import React, { Dispatch, SetStateAction } from "react";
import { Modal as ReactNativeModal } from "react-native";
import {
  TextInformationContainer,
  TextInformation,
  HeaderLink,
  PressableFinish,
  Container,
  Content,
  Overlay,
  Title,
  ContainerQrCode,
  PressableArea
} from "./styles";

import QRCode from "react-native-qrcode-svg";

export interface ModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  code: string
}

export default function ModalQrCode({ isOpen, setOpen, code }: ModalProps) {
  return (
    <ReactNativeModal
      animationType="fade"
      visible={isOpen}
      transparent={true}
      onRequestClose={() => {
        setOpen(false);
      }}
      style={{ borderWidth: 0, borderColor: "transparent" }}
    >
      <Overlay>
        <Container>
          <Title testID="modal_title">Escaneie o QR Code</Title>
          <Content>
            <ContainerQrCode> 
            <QRCode
              size={250}
              value={code.toString()}
              />
              </ContainerQrCode>
            <TextInformationContainer>
              <TextInformation>
                Escaneie o QR Code acima para acessar o link correspondente ao anúncio.
              </TextInformation>
            </TextInformationContainer>
            <PressableArea>
              <PressableFinish
                onPress={() => setOpen(false)}
                testID="modal_finish_button"
              >
                <HeaderLink>Voltar</HeaderLink>
              </PressableFinish>
            </PressableArea>
          </Content>
        </Container>
      </Overlay>
    </ReactNativeModal>
  );
}
