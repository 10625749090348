import { CommonActions, useNavigation } from "@react-navigation/native";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { CartContext } from "../contexts";
import { useModal } from "../hooks";
import { useNotificationBar } from "../hooks/use-notification-bar";
import { CartItem } from "../models/cart";
import { Planogram } from "../models/planogram";
import { DiffStoresAlert } from "../providers/components/diff-stores-alert";
import { RemoveItem } from "../providers/components/remove-item";

const planogramToCartItem = (planogram: Planogram): CartItem => {
  return {
    id: planogram.id,
    product_id: planogram.product_id,
    final_price_in_cents: parseFloat(planogram.price_final_in_cents),
    imageUrl: planogram.product.image_url || "",
    last_price_in_cents: parseFloat(planogram.price_list_in_cents),
    name: planogram.product.name,
    quantity: 1,
    stock_amount: planogram.stock_amount,
    store_id: planogram.store_id,
    is_for_legal_age: planogram?.product?.Category?.is_for_legal_age || false,
  };
};

export const CartProvider = ({ children }: React.PropsWithChildren) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [isAlreadyCreated, setIsAlreadyCreated] = useState(false);
  const [store, setStore] = useState<{ id: number }>();
  const { notify } = useNotificationBar();
  const modal = useModal();
  const navigation = useNavigation();
  const itemsTotal = useMemo(
    () => cart.reduce((total, item) => total + item.quantity, 0),
    [cart]
  );

  const cartIds = useMemo(() => cart.map((item) => item.id), [cart]);

  useEffect(() => {
    if (itemsTotal > 0) {
      setIsAlreadyCreated(false);
    }
  }, [itemsTotal]);

  const updateCartItem = async (
    planogram: Planogram,
    quantity: number,
    redirect?: boolean,
    clearCart?: boolean,
    confirmRemoveItem?: boolean,
    productStore?: { id: number }
  ): Promise<boolean> => {
    if (planogram.store_id !== store?.id) {
      if (clearCart || cart.length === 0) {
        setCart([
          {
            id: planogram.id,
            product_id: planogram.product_id,
            final_price_in_cents: parseFloat(planogram.price_final_in_cents),
            imageUrl: planogram.product.image_url,
            last_price_in_cents: parseFloat(planogram.price_list_in_cents),
            name: planogram.product.name,
            quantity,
            stock_amount: planogram.stock_amount,
            store_id: planogram.store_id,
            is_for_legal_age:
              planogram.product?.Category?.is_for_legal_age || false,
          },
        ]);
        setStore(productStore ?? store);
        if (!!redirect) {
          navigation.dispatch(
            CommonActions.navigate({
              name: "Carrinho",
            })
          );
        } else {
          notify("Item adicionado ao carrinho");
        }
        return true;
      } else {
        await modal.show(
          <DiffStoresAlert
            planogram={planogram}
            quantity={quantity}
            updateCartItem={updateCartItem}
            redirect={redirect}
          />
        );
        return false;
      }
    }
    const itemSearch = cart.find((item) => item.id === planogram.id);
    if (!itemSearch) {
      if (quantity <= 0) return false;
      setCart([
        ...cart,
        {
          id: planogram.id,
          product_id: planogram.product_id,
          final_price_in_cents: parseFloat(planogram.price_final_in_cents),
          imageUrl: planogram.product.image_url,
          last_price_in_cents: parseFloat(planogram.price_list_in_cents),
          name: planogram.product.name,
          quantity,
          stock_amount: planogram.stock_amount,
          store_id: planogram.store_id,
          is_for_legal_age:
            planogram?.product?.Category?.is_for_legal_age || false,
        },
      ]);
      if (!redirect) notify("Item adicionado ao carrinho");
    } else {
      if (quantity + itemSearch.quantity > 0)
        setCart(
          cart.map((item) => {
            if (item.id !== itemSearch.id) return item;
            return {
              ...item,
              quantity: quantity + itemSearch.quantity,
            };
          })
        );
      else {
        if (!confirmRemoveItem)
          await modal.show(
            <RemoveItem planogram={planogram} updateCartItem={updateCartItem} />
          );
        else {
          const newItems = cart.filter((item) => item.id !== itemSearch.id);
          setCart(newItems);
        }
      }
    }
    if (!!redirect) {
      navigation.dispatch(
        CommonActions.navigate({
          name: "Carrinho",
        })
      );
    }
    return true;
  };

  const clearCart = (isResetStore: boolean) => {
    if (isResetStore) setStore({} as Store);
    setCart([]);
  };

  const getCart = () => cart;

  const getStore = () => store;

  // effects for cart event
  useEffect(() => {
    window.addEventListener("shell-cart-event", (e: Event) => {
      const event = e as CustomEvent<Planogram>;
      const planogram = event.detail;

      setCart((_cart) => {
        const itemSearch = _cart.find((item) => item.id === planogram.id);
        if (!itemSearch) {
          return [
            ..._cart,
            {
              ...planogramToCartItem(planogram),
              quantity: 1,
            },
          ];
        } else {
          return _cart.map((item) => {
            if (item.id !== planogram.id) return item;
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          });
        }
      });
      navigation.dispatch(
        CommonActions.navigate({
          name: "Carrinho",
        })
      );
    });
    return () => window.removeEventListener("shell-cart-event", () => {});
  }, []);

  const value = useMemo(() => {
    return {
      updateCartItem,
      setIsAlreadyCreated,
      isAlreadyCreated,
      clearCart,
      getCart,
      getStore,
      itemsTotal,
      cartIds,
      setStore,
    };
  }, [cart, store]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
