import React from "react";
import { Button, Image } from "./styles";
import { Advert } from "@inhouse-market/sdk";

export interface propsAds {
  id: number;
  name: string;
  description: string;
  link: string;
  status: string;
  image: string;
}

const CarouselItem = ({
  item,
  handleNavigateTo,
}: {
  item: Advert.AdvertResponse;
  handleNavigateTo: (item: Advert.AdvertResponse) => void;
}) => {
  return (
    <Button onPress={() => handleNavigateTo(item)}>
      <Image source={{ uri: item?.image }} resizeMode="stretch" />
    </Button>
  );
};

export default CarouselItem;
