export const NumberToCurrency = (value?: number): string => {
  if (!value) return "R$0,00";
  let noDecimals = Math.round(value * 100).toString();
  if (noDecimals.length < 4) noDecimals = `000${noDecimals}`.slice(-4);
  const minus = noDecimals.startsWith("-") ? "-" : "";
  const match = noDecimals.match(/(\d{1,2})?((\d{3})*)(\d{2})$/);
  if (!match) return "R$0,00";
  const decimals = match[match.length - 1];
  let middle = match[2];
  if (middle.length < 3) middle = "";
  let front = !match[1] ? "" : match[1];
  for (let i = 0; i < middle.length / 3; i++) {
    front += "." + middle.slice(i * 3, i * 3 + 3);
  }
  if (front.startsWith(".")) front = front.slice(1);
  const frontWithoutZeros = front.replace(/^0*/, "");
  front = frontWithoutZeros.length === 0 ? "0" : frontWithoutZeros;
  return `R$${minus}${front},${decimals}`;
};

function range(start: number, end: number) {
  return Array.from({ length: end - start }, (_, i) => start + i);
}

export const getOnlyNumbers = (value: string): string =>
  String(value)?.replace(/[^\d]/g, "");

export default { getOnlyNumbers, range };
