import styled, { css } from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
  align-items: center;
  background: #fff;
  border-radius: 24px;
  border-width: 0px;
  border-color: transparent;
  width: 100%;
  max-width: 562px;
  height: 100%;
  max-height: 820px;
`;

export const Content = styled.View`
  width: 100%;
  border-top-width: 2px;
  border-top-color: #f6f6f6;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Title = styled.Text`
  font-size: 25px;
  font-weight: 700;
  font-family: ${theme.fonts.bold};
  margin-top: 28px;
  margin-bottom: 28px;
  text-align: center;
  color: #000000;
`;

export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const TextInformationContainer = styled.View`
  width: 100%;
  max-width: 252px;
  gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 22px 0 73px 0;
`;

export const TextContact = styled.Text`
  font-weight: 500;
  font-size: 18;
  text-align: center;
  font-family: ${theme.fonts.medium};
`;

export const TextInformation = styled.Text`
  font-size: 20px;
  line-height: 22.78px;
  font-weight: 400;
  font-family: ${theme.fonts.regular};
  text-align: center;
  color: #000000;
`;

export const ImageQrCode = styled.Image`
  width: 250px;
  height: 250px;
  margin-top: 44px;
`;

export const PressableFinish = styled.Pressable`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  border: none;
  border-width: 1.5px;
  border-color: #dadada;
  border-radius: 8px;
`;

export const PressableArea = styled.View`
  width: 100%;
  padding: 0px 22px;
  position: absolute;
  bottom: 27px;
`;

export const HeaderLink = styled.Text`
  font-size: 23px;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.medium};
`;

export const PixLogo = styled.Image`
  width: 240px;
  height: 240px;
`;

export const ContainerQrCode = styled.View`
`;