import styled from "styled-components/native";
import { theme } from "../../global/theme";


export const HeaderContainer = styled.View`
  margin: 0 19px;
  margin-top: 41px;
  display: flex;
  flex-direction: row;
`

export const HeaderButton = styled.TouchableOpacity<{ first: boolean }>`
  flex: 1;
  justify-content: center;
  align-items: ${props => props.first ? 'flex-start' : 'flex-end'};
`

export const HeaderLink = styled.Text`
  font-size: 15px; 
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  text-decoration-color:  ${theme.colors.primary};
`

export const HeaderTitle = styled.Text`
  font-size: 18px;
  font-family: ${theme.fonts.bold};
  text-align: center;
  color: ${theme.colors.tertiary};
  flex: 4;
`