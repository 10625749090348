import styled from "styled-components/native";
import styledJS from "styled-components";
import { theme } from "../../global/theme";

export const Box = styled.View<{ size?: number, alignItems?: string, justifyContent?: string }>`
	flex: ${(props) => props.size || null};
	height: 70px;
	display: flex;
	justify-content: ${(props) => props.justifyContent || null};
	align-items: ${(props) => props.alignItems || null};
	flex-direction: initial;

`;

export const Container = styled.View<{ backgroundColor: string }>`
	height: 90px;
	padding-top: 20px;
	padding-bottom: 10px;
	padding-horizontal: 20px;
	background-color: ${(props) => props.backgroundColor};
	justify-content: center;
`;

export const BackContainer = styled.TouchableOpacity``;

export const BackText = styled.Text`
	padding-bottom: 16px;
	text-decoration: underline;
	font-size: 14px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
`;

export const Content = styled.View`
	flex: 1;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.Text<{
	hasBackButton: boolean;
	isTitleCentered: boolean;
}>`
	margin-top: ${(props) => (props.hasBackButton ? "9px" : "44px")};
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	text-align: ${(props) => (props.isTitleCentered ? "center" : "left")};
	max-width: 80%;
`;

export const StoreIdentity = styled.View`
	flex-direction: row;
	flex: 1;
`;

export const StoreLogo = styled.Image.attrs({ resizeMode: "contain" })`
	width: 70px;
	height: 70px;
	border-radius: 7px;
	background-color: ${theme.colors.tertiary};
`;

export const InfoView = styled.View`
	margin-left: 26px;
	flex: 1;
	justify-content: center;
`;

export const StoreTitle = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.bold};
	color: #fff;
`;

export const Address = styled.Text`
	font-size: 14px;
	color: #71798e;
`;

export const PolygonButton = styled.TouchableOpacity`
	display: flex;
	width: 50px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const PolygonIcon = styled.Image``;

export const ArrowIcon = styled.Image`
	width: 5px;
	height: 5px;
	align-self: center;
	margin-left: 10px;
`;
export const ChangeStore = styled.Text`
	font-size: 9px;
	color: #f3f3f3;
	text-align: center;
`;

export const StoreDetails = styled.View`
	width: 100%;
	height: 100%;
	background-color: white;
	position: relative;
	z-index: 1;
	padding: 20px;
`;

export const SeeMoreSection = styled.View`
	align-items: center;
	position: absolute;
	width: 100%;
	top: 95px;
	height: 60%;
	z-index: 1;
`;

export const ContainerSeeMoreSection = styled.View`
	padding: 20px;
`;

export const ContainerContentList = styled.View`
	width: 100%;
	border-bottom-width: 0.4px;
	border-bottom-color: #c5c5c5;
	padding: 20px;
`;

export const LinkToStore = styledJS.a`
	text-decoration: none;
`;

export const TextApartmentComplex = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.medium};
	color: ${theme.colors.tertiary};
	text-decoration: none;
	text-decoration-style: none;
`;

export const TextApartmentComplexDistance = styled.Text`
	font-size: 11px;
	font-family: ${theme.fonts.medium};
	color: #999da6;
`;

export const ContainerOpacity = styled.View`
	width: 100%;
	height: 100%;
	z-index: 1;
	position: fixed;
	top: 66.5%;
	background: #000;
	opacity: 0.5;
`;

export const ContainerAddressButton = styled.TouchableOpacity`
	flex-direction: row;
	align-items: center;
`;

export const ContainerIconAlert = styled.TouchableOpacity`
	display: flex;
	flex-direction: row;
    justify-content: end;
	flex: 1;
	align-items: center;
`;

