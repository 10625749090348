import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useWindowDimensions } from "react-native";
import { Header } from "../../components/Header";
import { Product } from "../../components/Product";
import { useCart } from "../../hooks/use-cart";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
} from "@react-navigation/native";

import { FlashList } from "@shopify/flash-list";

import {
  Container,
  Content,
  CategoriesContainer,
  CategoriesContainerContent,
  CategoriesContent,
  TitleCategories,
  TextCategories,
  Products,
  ButtonContainer,
  TextLoadingCategory,
} from "./styles";
import { BaseClass } from "../../components/BaseClassCategory";
import SearchButton from "../../components/SearchButton";
import { useStore } from "../../hooks/use-store";
import useProducts from "../../store/products/useProducts";
import useCategories from "../../store/category/useCategories";
import {
  Category as CategoryService,
  Product as ProductSDK,
} from "@inhouse-market/sdk";

interface Category {
  id: number;
  name: string;
}

interface RouteParams {
  key: string;
  name: string;
  params: PropsCategory;
  path: any;
}

interface PropsCategory {
  name: string;
  id: number;
}

type Navigation = NavigationProp<any>;

type CategoryRouteProp = RouteProp<Record<string, RouteParams>, "Category">;

const Category: React.FC<{ route?: CategoryRouteProp }> = ({ route }) => {
  const { goBack } = useNavigation();
  const navigation = useNavigation<Navigation>();
  const [category, setCategory] = useState<PropsCategory>(
    route?.params?.params as PropsCategory
  );

  const [categoryName] = useState(category);
  const [, setSelectedProduct] = useState<ProductSDK.PlanogramItem>();
  const [numColumns, setNumColumns] = useState(2);
  const dimensions = useWindowDimensions();

  const flatListRef = useRef(null);

  const cart = useCart();
  const cartItems = useMemo(() => cart.getCart(), [cart]);
  const paramStore = useStore();


  const {
    data: productCategory,
    fetchNextPage: fetchNextPageProduct,
    hasNextPage: hasNextPageProduct,
    isFetching: isFetchingProduct,
    isInitialLoading,
  } = useProducts(Number(paramStore.storeId), category.id, category.name);

  const { data: categoryList } = useCategories(
    Number(paramStore.storeId),
    undefined
  );

  const flattenedProducts = useMemo(() => {
    return productCategory?.pages?.flatMap((page) => page?.data) ?? [];
  }, [productCategory, categoryName]);

  const refreshCategories = async (args: PropsCategory) => {
    if (args.name == category.name) {
      return goBack();
    }

    setCategory({
      ...category,
      name: args.name,
      id: args.id,
    });
  };

  const categoryItems = useMemo<CategoryService.CategoryItem[] | null>(() => {
    if (!categoryList) {
      return [];
    }

    return categoryList?.pages.flatMap((page) => page.data) || [];
  }, [category]);

  const handleChangeQuantity = (
    product: ProductSDK.PlanogramItem,
    qnty: number
  ) => {
    const cartItem = cartItems.find((item) => item.id === product.id);
    const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
    cart.updateCartItem(
      product,
      actualQnty,
      false,
      false,
      false,
      { id: Number(paramStore.storeId) }
    );
  };

  const handleNavigateToScreen = (
    screenName: string,
    params: Object | undefined = undefined
  ) => {
    navigation.navigate(screenName, {
      params,
    });
  };

  const renderItemProduct = ({ item }: { item: ProductSDK.PlanogramItem }) => (
    <Product
      key={item.id}
      title={item?.product.name}
      imgUrl={item?.product?.image_url}
      price_list={item?.price_list_in_cents / 100}
      quantity={
        cartItems.find((cartItem) => cartItem.id === item?.id)?.quantity || 0
      }
      maxQuantity={item.stock_amount}
      onQuantityChange={(qtd) => handleChangeQuantity(item, qtd)}
      quantityOpen={cart.cartIds.includes(item?.id)}
      onQuantityOpen={() => setSelectedProduct(item)}
      onQuantityPress={() => setSelectedProduct(undefined)}
      price_final={item.price_final_in_cents / 100}
      style={{
        marginBottom: 20,
        marginLeft: 20,
      }}
      testID={item.id.toString()}
      onPress={() => handleNavigateToScreen("Product", { product: item })}
    />
  );

  useEffect(() => {
    const productCardWidth = 160;
    const marginOfEachProductCard = 20;
    const cardQuantityPerLine = Math.floor(
      dimensions.width / (productCardWidth + marginOfEachProductCard)
    );
    setNumColumns(cardQuantityPerLine);
  }, [dimensions]);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    if (entry.isIntersecting && hasNextPageProduct) {
      fetchNextPageProduct();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (flatListRef.current) {
      observer.observe(flatListRef.current);
    }

    return () => {
      if (flatListRef.current) {
        observer.unobserve(flatListRef.current);
      }
    };
  }, [flatListRef.current]);

  const keyExtractor = useCallback(
    (item: any, i: number) => `${i}-${item?.id}`,
    []
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <CategoriesContainer showsHorizontalScrollIndicator={false}>
            <TitleCategories>Categorias</TitleCategories>
            <CategoriesContainerContent
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {categoryItems &&
                categoryItems?.map((item) => {
                  return (
                    <CategoriesContent
                      key={item.id.toString()}
                      selected={item.name == category.name}
                      onPress={() => {
                        refreshCategories(item);
                      }}
                    >
                      <TextCategories selected={item.name == category.name}>
                        {item.name}
                      </TextCategories>
                    </CategoriesContent>
                  );
                })}
            </CategoriesContainerContent>

            <Products>
              <FlashList
                ListHeaderComponent={() =>
                  !isInitialLoading ? <BaseClass title={category.name} /> : null
                }
                data={flattenedProducts || []}
                key={numColumns}
                extraData={cart?.cartIds}
                keyExtractor={keyExtractor}
                renderItem={renderItemProduct}
                numColumns={numColumns}
                estimatedItemSize={200}
                showsVerticalScrollIndicator={false}
                ListFooterComponent={() => (
                  <ButtonContainer ref={flatListRef}>
                    {isInitialLoading && (
                      <TextLoadingCategory>
                        Carregando Produtos...
                      </TextLoadingCategory>
                    )}
                  </ButtonContainer>
                )}
              />
            </Products>
            {isFetchingProduct && !isInitialLoading && (
              <ButtonContainer ref={flatListRef}>
                <TextLoadingCategory>
                  Carregando Produtos...
                </TextLoadingCategory>
              </ButtonContainer>
            )}
          </CategoriesContainer>
        </Content>
        {categoryItems && categoryItems?.length > 0 && (
          <SearchButton />
        )}
      </Container>
    </>
  );
};

export default Category;
