import React, { useRef, useState, useEffect } from "react";
import { Animated, StyleProp, ViewStyle } from "react-native";
import { useTheme } from "styled-components";
import { sleep } from "../../utils/sleep";
import { Container, Minus, Plus, QuantityText } from "./styles";
import { theme } from "../../global/theme";

interface QuantityPickerProps {
	value: number;
	maxValue: number;
	type?: "default" | "compact";
	open?: boolean;
	onOpen?: () => void;
	onChange: (value: number) => void;
	onPricePress?: () => void;
	style?: any;
	testID?: string;
}

export const QuantityPicker: React.FC<QuantityPickerProps> = ({
	value,
	maxValue,
	type,
	open,
	onOpen,
	onChange,
	onPricePress,
	style,
	testID,
}) => {
	const animation = useRef(new Animated.Value(0)).current;
	const [reallyClose, setReallyClose] = useState(true);

	const handleStatusChange = async () => {
		if (open) {
			Animated.timing(animation, {
				toValue: 100,
				duration: 200,
				useNativeDriver: false,
			}).start();
			setReallyClose(false);
		} else {
			Animated.timing(animation, {
				toValue: 0,
				duration: 200,
				useNativeDriver: false,
			}).start();
			await sleep(200);
			setReallyClose(true);
		}
	};

	useEffect(() => {
		handleStatusChange();
	}, [open]);

	const onMinusPress = () => {
		if (value < 1) return;
		if (!!onChange) onChange(value - 1);
	};

	const onPlusPress = async () => {
		if (!open && type === "compact") {
			if (!!onOpen) onOpen();
			if (!!onChange) onChange(value + 1);
		} else {
			if (value > maxValue - 1) return;
			if (!!onChange) onChange(value + 1);
		}
	};

	return (
		<Container
			type={type || "default"}
			style={{
				...style,
				width:
					type === "compact"
						? animation.interpolate({
								inputRange: [0, 100],
								outputRange: [44, 143],
						  })
						: undefined,
			}}
		>
			{((type === "compact" && (open || !reallyClose)) ||
				type !== "compact") && (
				<>
					<Minus
						color={
							value <= 0
								? theme.colors.disabledButtonText
								: theme.colors.secondary
						}
						onPress={onMinusPress}
						testID={`btn_minus_${testID}`}
					/>
					<QuantityText
						onPress={onPricePress}
						testID={`${testID}_qtd`}
					>
						{("00" + value).slice(value < 100 ? -2 : -3)}
					</QuantityText>
				</>
			)}
			<Plus
				color={
					value >= maxValue
						? theme.colors.disabledButtonText
						: theme.colors.secondary
				}
				onPress={onPlusPress}
				open={(type === "compact" && open) || type !== "compact"}
				testID={`btn_plus_${testID}`}
			/>
		</Container>
	);
};
