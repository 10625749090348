import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
`;
export const ErroIcon = styled.Image`
	width: 210px;
	height: 210px;
`;

export const ContainerContentErro = styled.View`
  flex: 1;
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-between;
	background-color: ${theme.colors.white};
`;

export const ContentErro = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
`;

export const ErroText = styled.Text`
	width: 317px;
	font-size: 18px;
	text-align: center;
	padding-top: 20px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
`;

export const ContentButtonProducts = styled.View`
	margin-top: 40px;
	margin-bottom: 80px;
`;
export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 335px;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;
export const TextConfirmButton = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.white};
	text-align: center;
	text-transform: uppercase;
`;
