import { useMutation, UseMutationResult, MutationFunction } from "@tanstack/react-query";
import api from "../../services/api";
import { ApiError, ParamsSendPayments, SallesResponseType, Status } from "./payment.types";
import { AxiosResponse } from "axios";

import * as Sentry from '@sentry/react';
import { account } from "../../utils/account";

const loginHidden = async () => {
  const response = await api.post("/sessions/login", account)
  const authToken = `Bearer ${response.data.token.token}`;
  api.defaults.headers.common["Authorization"] = authToken;
}

const sendPaymentsFetch = async (data: ParamsSendPayments): Promise<SallesResponseType> => {
  api.defaults.headers.common["x-device-id"] = data.deviceId || "";

  try {
    const response: AxiosResponse<SallesResponseType> = await api.post(`/sales`, {
      ...data,
      paymentMethodId: null,
      status: Status.STATUS_PENDING,
    });

    return response.data;
  } catch (error: any) {
    let errorMessage = null
    if (error?.response?.data?.errors && error?.response?.data?.errors?.length > 0) {
      errorMessage = error?.response?.data?.errors[0]?.message
    } else {
      errorMessage = error?.response?.message || error?.response?.data?.message || `erro ao tentar criar o pedido - ${data.paymentMethod}`
    }

    if (errorMessage === "E_UNAUTHORIZED_ACCESS: Unauthorized access") {
      await loginHidden()
      return sendPaymentsFetch(data);
    }
    //sentry error
    Sentry.captureException(error, {
      extra: {
        name: 'FetchDataError',
        value: data?.sallesTotal,
        date: new Date(),
        storeId: data?.storeId?.toString(),
        paymentMethod: data?.paymentMethod,
        deviceId: JSON.stringify(data?.deviceId),
        error: JSON.stringify(errorMessage),
        status: "failled",
        payload: JSON.stringify(data)
      },
      tags: {
        section: 'data-fetching',
        importance: 'high',
      },
      user: {
        id: 'totem',
        username: 'totem@shoppbud.com.br',
        email: 'totem@shoppbud.com.br',
      },
    });

    //event logs error
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: "LOGS_ERROR_PAYMENTS",
        payload: {
          error: JSON.stringify(errorMessage),
          erroGeneric: error?.response?.data || error?.response
        },
      })
    );

    throw error as ApiError;
  }
};

const mutationFn: MutationFunction<SallesResponseType, ParamsSendPayments> = async (data) => {
  return sendPaymentsFetch(data);
};

export const useSendPaymentsMutation = (): UseMutationResult<SallesResponseType, ApiError, ParamsSendPayments> => {
  return useMutation<SallesResponseType, ApiError, ParamsSendPayments, unknown>(mutationFn);
};
