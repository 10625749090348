import React from "react";
import { useModal } from "../../../hooks";
import { useNotificationBar } from "../../../hooks/use-notification-bar";
import { Planogram } from "../../../models/planogram";
import {
	ButtonContainer,
	Container,
	Description,
	OptionButton,
	OptionButtonText,
	Title,
	Content,
} from "./styles";
import { MaterialCommunityIcons } from "@expo/vector-icons";

interface RemoveItemProps {
	planogram: Planogram;
	updateCartItem: (
		planogram: Planogram,
		quantity: number,
		redirect?: boolean,
		clearCart?: boolean,
		confirmRemoveItem?: boolean
	) => Promise<boolean>;
}

export const RemoveItem: React.FC<RemoveItemProps> = ({
	planogram,
	updateCartItem,
}) => {
	const modal = useModal();
	const { notify } = useNotificationBar();

	const onConfirm = async () => {
		await updateCartItem(planogram, -1, false, false, true);
		await modal.close();
		notify("Item removido do carrinho");
	};

	const onCancel = async () => {
		await modal.close();
	};

	return (
		<Container>
			<Content>
				<MaterialCommunityIcons
					name="delete-outline"
					size={60}
					color="red"
				/>
				<Title>Confirmação</Title>
				<Description>
					Tem certeza que deseja retirar o produto do carrinho?
				</Description>
			</Content>
			<ButtonContainer>
				<OptionButton
					onPress={onCancel}
					testID="btn_cancel_remotion"
					left={false}
				>
					<OptionButtonText>CANCELAR</OptionButtonText>
				</OptionButton>
				<OptionButton
					left
					onPress={onConfirm}
					testID="btn_confirm_remotion"
				>
					<OptionButtonText>SIM</OptionButtonText>
				</OptionButton>
			</ButtonContainer>
		</Container>
	);
};
