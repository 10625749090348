import { useNavigation } from '@react-navigation/core';
import { CommonActions } from '@react-navigation/routers';
import React from 'react';
import { useModal } from '../../../hooks';
import { useNotificationBar } from '../../../hooks/use-notification-bar';
import { Planogram } from '../../../models/planogram';
import {
  CancelButton,
  CancelButtonWrapper,
  Container,
  ContinueButton,
  Description,
  Title,
} from './styles';

interface DiffStoresAlertProps {
  planogram: Planogram;
  quantity: number;
  updateCartItem: (
    planogram: Planogram,
    quantity: number,
    redirect?: boolean,
    clearCart?: boolean,
  ) => Promise<boolean>;
  redirect?: boolean;
}

export const DiffStoresAlert: React.FC<DiffStoresAlertProps> = ({
  planogram,
  quantity,
  updateCartItem,
  redirect,
}) => {
  const modal = useModal();
  const navigation = useNavigation();
  const { notify } = useNotificationBar();

  const handleContinuePress = async () => {
    updateCartItem(planogram, quantity, !!redirect, true);
    await modal.close();
    if (!!redirect) {
      navigation.dispatch(
        CommonActions.navigate({
          name: 'Cart',
        }),
      );
    } else notify('Item adicionado ao carrinho');
  };

  const handleCancelPress = async () => {
    await modal.close();
  };

  return (
    <Container>
      <Title>Você só pode adicionar itens de uma loja por vez</Title>
      <Description>
        Deseja esvaziar o carrinho e adicionar este item?
      </Description>
      <ContinueButton
        type="secondary"
        onPress={handleContinuePress}
        testID="btn_clean_cart"
      >
        Esvaziar sacola e adicionar
      </ContinueButton>
      <CancelButtonWrapper
        onPress={handleCancelPress}
        testID="btn_cancel_clean_cart"
      >
        <CancelButton>Cancelar</CancelButton>
      </CancelButtonWrapper>
    </Container>
  );
};
