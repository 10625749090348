import { useQuery, UseQueryResult } from '@tanstack/react-query';
import api from "../../services/api"
import { PaymentMethodsResponse } from './payment.types';

export const METHOD_PAYMENTS = 'METHOD_PAYMENTS';

export function useGetPaymentMethod(storeId: string | null): UseQueryResult<PaymentMethodsResponse, unknown> {
  const getPaymentMethods = async () => {
    const response = await api.get<PaymentMethodsResponse>(
      `/store/${storeId}/payment-methods`,
    );

    if (response && response.data?.payments) {
      return response.data;
    }

    return { payments: [] }
  };

  return useQuery({
    queryKey: [METHOD_PAYMENTS],
    queryFn: getPaymentMethods,
    enabled: !!storeId,
    refetchInterval: 20 * 1000, // 20 seconds
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true
  });
}
