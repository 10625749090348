export interface ParamsSendPayments {
  items: Product[],
  paymentMethod: string,
  storeId: string,
  pwaId: string | null,
  pwaPush: string,
  currentCpf: string
  deviceId: number | string
  sallesTotal: number | null
}

export interface Product {
  productName: string,
  planogramId: number,
  productId: number,
  price: number,
  listPrice: number,
  quantity: number,
}

export interface SallesResponseType {
  id: number;
  invoiceId: string | null;
  pix: string;
  saleId: number;
  url: string;
  expires_at: string
  paymentMethod: string
  totalPrice: number
}

export const Status = {
  STATUS_PENDING: "pending" as string,
};

export const TypePaymentMethod = {
  PLUGPAG_CREDIT: 'plugpag_credit' as string,
  PLUGPAG_DEBIT: 'plugpag_debit' as string,
  PLUGPAG_VOUCHER: 'plugpag_voucher' as string,
  PIX: 'pix' as string,
  CREDIT_CARD: 'credit_card' as string,
};

export interface MethodPaymentEnabled {
  is_active: number;
  is_gateway_active: number;
  is_global_active: number;
  name: string;
  id: number;
  store_id: number;
}

export interface PaymentMethodsResponse {
  payments: MethodPaymentEnabled[]
}

interface ErrorMessage {
  message?: string
}

export interface ApiError {
  response: {
    data: {
      errors: ErrorMessage[];
      message?: string;
    };
  };
}