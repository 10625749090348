import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import {
	Container,
	ContainerRow,
	Content,
	IconContainer,
	Icon,
	TextButton,
	Version,
	VersionText,
} from "./styles";

import pkg from "../../../package.json";
import { formatDate } from "../../utils/formatDate";
import HeaderNavigation from "../../components/HeaderNavigation";
import ModalSupport from "../../components/ModalSupport";
import { useShellData } from "../../providers/shell-provider";

const CartEmpty = require("../../assets/cart_clean.svg");
const MachineSetting = require("../../assets/setting.svg");
const Support = require("../../assets/support.svg");

export function compareVersions(currentVersion: string, minVersion: string): boolean {
  const currentParts = currentVersion.split('.').map(Number);
  const minParts = minVersion.split('.').map(Number);

  const maxLength = Math.max(currentParts.length, minParts.length);

  for (let i = 0; i < maxLength; i++) {
    const currentPart = currentParts[i] || 0;
    const minPart = minParts[i] || 0;

    if (currentPart > minPart) return true;
    if (currentPart < minPart) return false;
  }

  return true; // versões são iguais
}

type Navigation = NavigationProp<any>;

const Others: React.FC = () => {
	const navigation = useNavigation<Navigation>();
	const [openSupport, setOpenSupport] = useState(false);

	const handleChangeToMyBuys = () => {
		navigation.navigate("MyBuys" as never);
	};

	const shellData = useShellData();

	const sendOpenMachineEvent = () => {
		window.ReactNativeWebView?.postMessage(JSON.stringify({
			type: "OPEN_TOTEM_CONFIG",
		}));
	}
	

	return (
		<>
			<Container>
				<HeaderNavigation enableButtonLeft={true} enableButtonRight={false} title="Outros" />
				<ContainerRow onPress={handleChangeToMyBuys} style={{ marginTop: 22 }}>
					<IconContainer>
						<Icon
							resizeMode="contain"
							source={CartEmpty}
						/>
					</IconContainer>
					<TextButton>Comprovantes de compra</TextButton>
				</ContainerRow>
				<ContainerRow onPress={() => setOpenSupport(true)}>
					<IconContainer>
						<Icon
							resizeMode="contain"
							source={Support}
						/>
					</IconContainer>
					<TextButton>Suporte</TextButton>
				</ContainerRow>
				{shellData && (
					<ContainerRow onPress={() => sendOpenMachineEvent()}>
						<IconContainer>
							<Icon
								resizeMode="contain"
								source={MachineSetting}
							/>
						</IconContainer>
						<TextButton>Configurações do totem</TextButton>
					</ContainerRow>
				)}
				<Version>
					<VersionText>
						PWA: {pkg.version} - {formatDate(new Date(Number(pkg.releasedAt)))}
						{shellData && (
							<>
								{"\n"}
								Shell: {shellData.version} {"\n"}
								POS Autenticado: {shellData.pos.authenticated ? "✅" : "❌"} {"\n"}
								POS ID: {shellData.pos.device?.name} {"\n"}
								POS Serial: {shellData.pos.device?.id} {"\n"}
								POS Pareado: {shellData.pos.paired ? "✅" : "❌"} {"\n"}
								POS Próximo: {shellData.pos.nearby ? "✅" : "❌"} {"\n"}
								ID da Loja: {shellData.storeId} {"\n"}
								{compareVersions(shellData.version, "1.16.0") && (
									`UUID do Android: ${shellData?.deviceId ? shellData?.deviceId : "-"}` 
								)} {compareVersions(shellData.version, "1.16.0") && "\n"}
								Permissoes: BT {shellData.pos.bluetoothAccessPermission ? "✅" : "❌"} | Arquivo {shellData.pos.fileAccessPermission ? "✅" : "❌"} | Localização {shellData.pos.locationAccessPermission ? "✅" : "❌"} | Telefone {shellData.pos.phoneAccessPermission ? "✅" : "❌"}
							</>
						)}
					</VersionText>
				</Version>
				<ModalSupport isOpen={openSupport} setOpen={setOpenSupport} />
			</Container>
		</>
	);
};

export default Others;

