import {  useMutation } from "@tanstack/react-query";
import api from "../../services/api"

interface ParamsSendEmail {
  email: string
  saleId: number
}

const sendEmailFetch = async <T>(email: string, saleId: number): Promise<T> => {
  const response = await api.post(`sales/send-confirmation`, {
    saleId,
    email
  });
  return response.data;
}

export const useSendEmailMutation =  () => {

  return useMutation({
    mutationFn: (data: ParamsSendEmail) => sendEmailFetch(data.email, data.saleId)
  })

}