import { createContext } from "react";
import { CartItem } from "../models/cart";
import { Store } from "../models/store";
import { Product } from "@inhouse-market/sdk";

interface CartContextProps {
  updateCartItem: (
    planogram: Product.PlanogramItem,
    quantity: number,
    redirect: boolean,
    clearCart: boolean,
    confirmRemoveItem: boolean,
    store?: { id: number}
  ) => Promise<boolean>;
  clearCart: (isResetStore: boolean) => void;
  getCart: () => CartItem[];
  cartIds: number[];
  getStore: () => Store | undefined;
  itemsTotal: number;
  isAlreadyCreated: boolean;
  setIsAlreadyCreated: (bool: boolean) => void;
  setStore: (value: Store) => void;
}

export const CartContext = createContext({} as CartContextProps);
