import { useEffect, useRef, useState } from 'react';

const STATUS = {
  IDLE: 'IDLE',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
} as const;

type Status = typeof STATUS[keyof typeof STATUS];

export function useTimer(callback: () => void, ms: number) {
  const interval = useRef<NodeJS.Timeout>();
  const [status, setStatus] = useState<Status>('IDLE');
  const [time, setTime] = useState(ms);

  const start = () => {
    setStatus(STATUS.RUNNING);
  };
  const stop = () => {
    setStatus(STATUS.STOPPED);
    clearInterval(interval.current);
  };

  const set = (_ms: number) => {
    setTime(_ms);
  };

  const reset = () => {
    setStatus(STATUS.IDLE);
    setTime(ms);
  };

  useEffect(() => {
    if (time <= 0 && status === STATUS.RUNNING) {
      stop();
      callback();
    }
  }, [time, status]);

  useEffect(() => {
    if (status === STATUS.RUNNING) {
      interval.current = setInterval(() => {
        setTime((t) => t - 1000);
      }, 1000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [status]);

  return {
    reset,
    set,
    start,
    stop,
    time,
  };
}
