import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { NavigationProp, useFocusEffect, useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CartItem } from "../../components/CartItem";
import { ModalAgeRestricted } from "../../components/ModalAgeRestricted";

import {
  Container,
  Content,
  ContainerContentEmptyCart,
  ContentEmptyCart,
  EmptyCartContainer,
  EmptyCartIcon,
  NoItemsText,
  ConfirmButton,
  TextConfirmButton,
  TotalContainer,
  TotalContainerContent,
  TotalContainerContentTextValue,
  TotalContainerContentValue,
  ItemsScroll,
  CartContentContainer,
  BtnContainer,
  ContentButtonProducts,
  LineContainer,
  LineText,
  ChangeButton,
  TextChange,
  StoreAreaInfo,
  StoreLogo,
  StoreAreaTitle,
  StoreTitle,
  StoreAddress,
  PurchaseDetails,
  BtnArea,
  CardItemsArea,
  LineArea,
  PaymentLineContainer,
  PaymentDetailsContainer,
  PaymentDetailsTitle,
  PaymentDetailsDescription,
  PaymentChangeText,
  NoteIcon,
  PaymentTitle,
  IconContaine,
} from "./styles";
import { useCart } from "../../hooks/use-cart";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import HeaderNavigation from "../../components/HeaderNavigation";
import ModalPayments from "../../components/ModalPayments";
import { useSendPaymentsMutation } from "../../store/payments/createPayment";
import {
  ParamsSendPayments,
  TypePaymentMethod,
} from "../../store/payments/payment.types";
import { useStore } from "../../hooks/use-store";
import ModalSupport from "../../components/ModalSupport";
import { ActivityIndicator, ScrollView } from "react-native";
import { useGetPaymentMethod } from "../../store/payments/payment-methods";
import { useShellData } from "../../providers/shell-provider";
import ModalAddCpf from "../../components/ModalAddCpf";
import { getFormattedRemoteConfig } from "../../utils/remoteConfig";

const arrayMessageErros = [
  "A transação não foi autorizada pelo emissor do seu cartão. Por favor, entre em contato com o emissor ou utilize outra forma de pagamento.",
];

const emptyCartIcon = require("../../../assets/emptyCart.svg");
const noteIcon = require("../../../assets/note.svg");

type Navigation = NavigationProp<any>;

const Cart: React.FC = () => {
  const myCart = useCart();
  const paramStore = useStore();
  const cartItems = useMemo(() => myCart.getCart(), [myCart]);
  const storeInfo = useMemo(() => myCart.getStore(), [myCart]);
  const [isOpenModalAge, setIsOpenModalAge] = useState<boolean>(false);
  const [openModalPayment, setOpenModalPayment] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<string>("");
  const [flowPaymentModal, setFlowPaymentModal] = useState("selectMethods");
  const [errorPaymentModal, setErrorPaymentModal] = useState<{
    title: string;
    description: string;
  }>({
    title: "Falha na operação",
    description: "Desculpe-nos, algo deu errado ao processar seu pagamento.",
  });
  const [showSupport, setShowSupport] = useState(false);

  const [userCPF, setUserCPF] = useState("");
  const [showInputCPF, setShowInputCPF] = useState(false);

  const listItemRef = useRef<any>(null)

  const remoteConfig =
    getFormattedRemoteConfig()?.ENABLED_TOTEM_ADD_CPF === "true";
  const navigation = useNavigation<Navigation>();
  const shell = useShellData();

  const isActiveFiscal = remoteConfig && !!storeInfo?.shopManager?.emit_invoice;

  const {
    isLoading,
    isError: isErrorPayment,
    mutate,
    data: paymentResult,
    reset,
    error: errorPayment,
  } = useSendPaymentsMutation();

  const { data: methodPaymentEnabled, isLoading: isLoadingPaymentMethod } =
    useGetPaymentMethod(paramStore.storeId);

  const handleNavigateToStore = () => {
    navigation.navigate("Loja");
  };

  const saveItemToAsyncStorage = async (value: "true" | "false") => {
    await AsyncStorage.setItem("is_open_shell", value);
  };

  const finalTotal = React.useMemo(() => {
    return cartItems.reduce((total, item) => {
      return total + item.final_price_in_cents * item.quantity;
    }, 0);
  }, [cartItems]);

  const getSubTotal = useCallback(
    () =>
      cartItems.reduce(
        (all, cartItem) =>
          cartItem.last_price_in_cents > cartItem.final_price_in_cents
            ? (cartItem.last_price_in_cents / 100) * cartItem.quantity + all
            : (cartItem.final_price_in_cents / 100) * cartItem.quantity + all,
        0
      ),
    [cartItems]
  );

  const getDiscountInCents = () => {
    const listPriceTotal = cartItems.reduce((total, item) => {
      return total + item.last_price_in_cents * item.quantity;
    }, 0);

    return finalTotal < listPriceTotal ? listPriceTotal - finalTotal : 0;
  };

  const verifyAgeRestricted = () => {
    const hasMinorProhibitedProducts = cartItems.reduce(
      (all, curr) => all || curr.is_for_legal_age,
      false
    );

    if (hasMinorProhibitedProducts) {
      setIsOpenModalAge(true);
      return;
    }

    onConfirmationPayment();
  };

  const onConfirmationPayment = async () => {
    const hasPlugpagMethod = methodPaymentEnabled?.payments?.some(
      (item) =>
        item.name === TypePaymentMethod.PLUGPAG_DEBIT ||
        item.name === TypePaymentMethod.PLUGPAG_CREDIT ||
        item.name === TypePaymentMethod.PLUGPAG_VOUCHER
    );

    if (
      shell &&
      shell.pos.paired &&
      shell.pos.nearby &&
      shell.pos.authenticated &&
      hasPlugpagMethod
    ) {
      setOpenModalPayment(true);
    } else {
      setSelectedPayment(TypePaymentMethod.PIX);
      onFinishedPayment(TypePaymentMethod.PIX);
    }
  };

  const onFinishedPayment = async (paymentMethod: string) => {
    const pwaId = await AsyncStorage.getItem("id_pwa");
    const cpf = userCPF && userCPF?.replace(/[\.-]/g, "");
    const payload: ParamsSendPayments = {
      items: cartItems.map((item) => {
        return {
          productName: item.name,
          planogramId: item.id,
          productId: item.product_id,
          price: item.final_price_in_cents,
          listPrice: item.last_price_in_cents,
          quantity: item.quantity,
        };
      }),
      paymentMethod,
      storeId: paramStore.storeId,
      pwaId: pwaId,
      pwaPush: "",
      currentCpf: cpf || "00000000000",
      deviceId: shell?.deviceId || "",
      sallesTotal: finalTotal,
    };

    mutate(payload);
  };

  const onMessageEvent = (event: Event) => {
    const message = event as MessageEvent<{ type: string; payload?: any }>;
    const { type, payload } = message.data;

    if (type === "REQUEST_POS_PAYMENT_SUCCESS") {
      navigation.navigate("ComprovantePagamento", {
        purchaseId: payload.purchaseId,
        saleId: payload.saleId,
        success: true,
        paymentMethod: payload?.paymentMethod,
      });

      myCart.clearCart(false); // reset cart
    }

    if (type === "REQUEST_POPUP_SUPORT") {
      setShowSupport(true);
    }
  };

  useEffect(() => {
    document.addEventListener("message", onMessageEvent);
    return () => document.removeEventListener("message", onMessageEvent);
  }, []);

  const sendMessage = () => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: "REQUEST_POS_PAYMENT",
        payload: paymentResult,
      })
    );
  };

  const resetState = () => {
    setSelectedPayment("");
    setOpenModalPayment(false);
    reset();
    setUserCPF("");
  };

  const handlePixPayment = () => {
    if (selectedPayment === TypePaymentMethod.PIX && paymentResult?.pix) {
      navigation.navigate("Pagar", paymentResult);
      resetState();
    }
  };

  const handlePlugPagPayment = async () => {
    const isPlugPagPayment =
      selectedPayment === TypePaymentMethod.PLUGPAG_CREDIT ||
      selectedPayment === TypePaymentMethod.PLUGPAG_VOUCHER ||
      selectedPayment === TypePaymentMethod.PLUGPAG_DEBIT;

    if (paymentResult?.id && isPlugPagPayment) {
      await saveItemToAsyncStorage("true");
      sendMessage();
      resetState();
    }
  };

  useEffect(() => {
    handlePixPayment();
    handlePlugPagPayment();
  }, [paymentResult]);

  useEffect(() => {
    if (!openModalPayment) {
      setFlowPaymentModal("selectMethods");
    }
  }, [openModalPayment]);

  useEffect(() => {
    if (errorPayment && isErrorPayment) {
      const errorMessage = errorPayment?.response?.data?.message;
      const code =
        (errorPayment?.response?.data?.message as any)?.code === "BAD_REQUEST";

      if (typeof errorMessage === "string") {
        if (errorMessage.includes("FRAUD_PREVENTION")) {
          setErrorPaymentModal({
            title: "Atenção",
            description:
              "Sua conta foi bloqueada por motivos de segurança. Favor entrar em contato com o suporte",
          });
        }
        if (arrayMessageErros.includes(errorMessage)) {
          setErrorPaymentModal({
            title: "Erro!",
            description: errorMessage,
          });
        }
      }
      if (
        !code &&
        errorMessage?.endsWith("estoque maior que registrado na loja.")
      ) {
        setErrorPaymentModal({
          title: "Erro!",
          description: errorMessage,
        });
      }
      if (
        !code &&
        errorMessage?.endsWith("a loja não permite essa operação.")
      ) {
        setErrorPaymentModal({
          title: "Erro!",
          description: errorMessage,
        });
      }
      if (!code && errorMessage?.endsWith("bloqueado para a venda")) {
        setErrorPaymentModal({
          title: "Erro!",
          description: errorMessage,
        });
      }

      setSelectedPayment("");
      setOpenModalPayment(false);

      setFlowPaymentModal("failed");
      setOpenModalPayment(true);
    }
  }, [errorPayment]);

  useFocusEffect(
    React.useCallback(() => {
      const scrollToBottom = () => {
        if (listItemRef?.current && cartItems?.length > 0) {
          listItemRef?.current?.scrollTo({ y: (cartItems.length - 2) * 130, animated: true });
        }
      };

      scrollToBottom();

      return () => { };
    }, [cartItems?.length])
  );

  return (
    <>
      {openModalPayment && (
        <ModalPayments
          isOpen={openModalPayment}
          setOpen={setOpenModalPayment}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
          onFinished={onFinishedPayment}
          loading={isLoading}
          flow={flowPaymentModal}
          setFlow={setFlowPaymentModal}
          tryAgain={sendMessage}
          setShowSupport={setShowSupport}
          errorPaymentModal={errorPaymentModal}
        />
      )}
      {showSupport && (
        <ModalSupport isOpen={showSupport} setOpen={setShowSupport} />
      )}
      <Container>
        <Content>
          <HeaderNavigation
            title="Carrinho"
            enableButtonLeft
            enableButtonRight={false}
          />
          {cartItems.length === 0 ? (
            <EmptyCartContainer>
              <ContainerContentEmptyCart>
                <ContentEmptyCart>
                  <EmptyCartIcon source={emptyCartIcon} />
                  <NoItemsText>
                    Seu carrinho de compras ainda está vázio
                  </NoItemsText>
                </ContentEmptyCart>
                <ContentButtonProducts>
                  <ConfirmButton onPress={handleNavigateToStore}>
                    <TextConfirmButton>VER PRODUTOS</TextConfirmButton>
                  </ConfirmButton>
                </ContentButtonProducts>
              </ContainerContentEmptyCart>
            </EmptyCartContainer>
          ) : (
            <>
              <StoreAreaInfo>
                <StoreLogo source={{ uri: storeInfo?.logo }} />
                <StoreAreaTitle>
                  <StoreTitle numberOfLines={1} ellipsizeMode="tail">
                    {storeInfo?.name}
                  </StoreTitle>
                  <StoreAddress>{storeInfo?.address.street}</StoreAddress>
                </StoreAreaTitle>
              </StoreAreaInfo>
              <CartContentContainer>
                <TotalContainer>
                  <TotalContainerContent>
                    <TotalContainerContentTextValue>
                      Total ({myCart.itemsTotal} un)
                    </TotalContainerContentTextValue>
                  </TotalContainerContent>
                  <TotalContainerContentValue>
                    <TotalContainerContentTextValue>
                      {NumberToCurrency(
                        cartItems.reduce(
                          (all, curr) =>
                            all +
                            (curr.final_price_in_cents / 100) * curr.quantity,
                          0
                        )
                      )}
                    </TotalContainerContentTextValue>
                  </TotalContainerContentValue>
                </TotalContainer>
                <ItemsScroll ref={listItemRef}>
                  <CardItemsArea>
                    {cartItems.map((item, index) => (
                      <CartItem
                        key={index}
                        item={item}
                        isLast={cartItems.length - 1 === index}
                      />
                    ))}
                  </CardItemsArea>
                  <LineArea>
                    <LineContainer first>
                      <LineText>Subtotal</LineText>
                      <LineText testID="subtotal_purchase">
                        {NumberToCurrency(getSubTotal())}
                      </LineText>
                    </LineContainer>
                    <LineContainer>
                      <LineText>Desconto</LineText>
                      <LineText>
                        {NumberToCurrency(getDiscountInCents() / 100)}
                      </LineText>
                    </LineContainer>

                    <LineContainer isLast>
                      <LineText>Total</LineText>
                      <LineText testID="total_purchase">
                        {NumberToCurrency(finalTotal / 100)}
                      </LineText>
                    </LineContainer>
                  </LineArea>

                  <PurchaseDetails padding={isActiveFiscal}>
                    <TotalContainerContent>
                      <TotalContainerContentTextValue isLeft={isActiveFiscal}>
                        Total ({myCart.itemsTotal} un)
                      </TotalContainerContentTextValue>
                    </TotalContainerContent>
                    <TotalContainerContentValue>
                      <TotalContainerContentTextValue isRigth={isActiveFiscal}>
                        {NumberToCurrency(
                          cartItems.reduce(
                            (all, curr) =>
                              all +
                              (curr.final_price_in_cents / 100) * curr.quantity,
                            0
                          )
                        )}
                      </TotalContainerContentTextValue>
                    </TotalContainerContentValue>
                  </PurchaseDetails>
                  {isActiveFiscal && (
                    <>
                      <PaymentTitle>Nota Fiscal</PaymentTitle>
                      <PaymentLineContainer>
                        <IconContaine>
                          <NoteIcon source={noteIcon} resizeMode="contain" />
                        </IconContaine>
                        <PaymentDetailsContainer>
                          <PaymentDetailsTitle testID="cpf_label">
                            {userCPF
                              ? "Alterar CPF da nota fiscal"
                              : "Deseja adicionar CPF na nota fiscal ?"}
                          </PaymentDetailsTitle>
                          {userCPF && (
                            <PaymentDetailsDescription testID="cpf_label_number">
                              {userCPF}
                            </PaymentDetailsDescription>
                          )}
                        </PaymentDetailsContainer>
                        <PaymentChangeText
                          color={null}
                          onPress={() => setShowInputCPF(true)}
                          testID="btn_add_cpf"
                        >
                          {userCPF ? "Trocar" : "Adicionar"}
                        </PaymentChangeText>
                      </PaymentLineContainer>
                    </>
                  )}
                </ItemsScroll>
                <BtnContainer>
                  <BtnArea>
                    <ChangeButton
                      onPress={handleNavigateToStore}
                      disabled={isLoading}
                    >
                      <TextChange>Continuar comprando</TextChange>
                    </ChangeButton>
                  </BtnArea>

                  <BtnArea>
                    <ConfirmButton
                      onPress={() => verifyAgeRestricted()}
                      disabled={isLoading}
                    >
                      <TextConfirmButton>
                        {isLoading ? (
                          <ActivityIndicator color={"#FFF"} size={"small"} />
                        ) : (
                          "Finalizar"
                        )}
                      </TextConfirmButton>
                    </ConfirmButton>
                  </BtnArea>
                </BtnContainer>
              </CartContentContainer>
            </>
          )}
        </Content>
      </Container>

      <ModalAgeRestricted
        isOpen={isOpenModalAge}
        setIsOpen={setIsOpenModalAge}
        title="Confirmação de idade"
        description="Você confirma ter 18 anos de idade ou mais ?"
        action={() => onConfirmationPayment()}
        testID="modal_age_verification"
      />
      {showInputCPF && (
        <ModalAddCpf
          userCPF={userCPF}
          setUserCPF={setUserCPF}
          setOpen={setShowInputCPF}
        />
      )}
    </>
  );
};

export default Cart;
