import React from "react";
import { Header } from "../../components/Header";
import {
	ContainerContentErro,
	ContentErro,
	ErroIcon,
	ErroText,
	ConfirmButton,
	ContentButtonProducts,
	TextConfirmButton,
} from "./styles";
import { NavigationProp, useNavigation } from "@react-navigation/native";

type Navigation = NavigationProp<any>;

const Erro: React.FC = () => {
	const navigation = useNavigation<Navigation>();

	const handleNavigateTo = () => {
		navigation.navigate("Loja");
	};

	const emptyIcon = require("../../../assets/error.png");

	return (
		<>
			<Header />
			<ContainerContentErro>
				<ContentErro>
					<ErroIcon source={emptyIcon} />
					<ErroText>
						Desculpe ocorreu um erro ao carregar a página. {"\n"}{" "}
						Tente novamente mais tarde.
					</ErroText>
				</ContentErro>
				<ContentButtonProducts>
					<ConfirmButton onPress={handleNavigateTo}>
						<TextConfirmButton>VER PRODUTOS</TextConfirmButton>
					</ConfirmButton>
				</ContentButtonProducts>
			</ContainerContentErro>
		</>
	);
};

export default Erro;
