import axios, { AxiosRequestConfig } from "axios";
import env from "../env";
import pkg from "../../package.json";

const api = axios.create({
  baseURL: env.API,
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const headers = config.headers || {};

  headers["x-totem-version"] = pkg.version || "unknown";
  headers["x-platform"] = "web";

  return config;
});

export default api;
