import React, { Dispatch, SetStateAction } from "react";
import { Image, Modal as ReactNativeModal, View } from "react-native";
import {
  TextInformationContainer,
  TextInformation,
  HeaderLink,
  PressableFinish,
  TextContact,
  Container,
  Content,
  Overlay,
  ImageQrCode,
  Title,
  PressableArea,
} from "./styles";

export interface ModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
}

import QrCodeSupport from "../../assets/qrcodesupport.png";
import Contact from "../../assets/whatsapp-icon.png";

export default function ModalSupport({ isOpen, setOpen, loading }: ModalProps) {
  return (
    <ReactNativeModal
      animationType="fade"
      visible={isOpen}
      transparent={true}
      onRequestClose={() => {
        setOpen(false);
      }}
      style={{ borderWidth: 0, borderColor: "transparent" }}
    >
      <Overlay>
        <Container>
          <Title testID="modal_title">Fale com o suporte</Title>

          <Content>
            <ImageQrCode source={QrCodeSupport} resizeMode="contain" />
            <TextInformationContainer>
              <TextInformation>
                Escaneie o QR Code e fale com nosso suporte pelo Whatsapp
              </TextInformation>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 8,
                }}
              >
                <Image source={Contact} style={{ width: 22, height: 22 }} />
                <TextContact>+55 (85) 2180-6454</TextContact>
              </View>
            </TextInformationContainer>
            <PressableArea>
              <PressableFinish
                onPress={() => setOpen(false)}
                testID="modal_finish_button"
              >
                <HeaderLink>Voltar</HeaderLink>
              </PressableFinish>
            </PressableArea>
          </Content>
        </Container>
      </Overlay>
    </ReactNativeModal>
  );
}
