import { Advert } from "@inhouse-market/sdk";
import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "../../services/query";
import { apiSDK } from "../../services/apiSDK";

export default function useAdverts(params: Advert.RequestAllAdverts) {
  const queryFn = async () => {
    const data = await apiSDK.product.advert.get(params);
    return data;
  };

  return useQuery({
    queryFn,
    queryKey: queryKeys.ADVERTS(params.showIn, params.storeId),
    refetchInterval: 5 * 60 * 1000,
    enabled: !!params.storeId
  });
}
