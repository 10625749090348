import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const PixValueContainer = styled.View`
	margin-bottom: 26px;
`;

export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.black};
	font-size: 20px;
`;

export const ContainerRow = styled.TouchableOpacity`
	flex-direction: row;
	padding: 18px 20px 18px 20px;
	align-items: center;
	height: 67px;
`;

export const IconContainer = styled.View`
	margin-right: 20px;
`;

export const Icon = styled.Image`
	width: 21px;
	height: 21px;
`;

export const TextButton = styled.Text`
	font-size: 13px;
	color: ${theme.colors.tertiary};
`;

export const Version = styled.View`
	width: 100%;
	padding: 10px;
	align-items: center;
	flex: 1;
	justify-content: flex-end;
`;

export const VersionText = styled.Text`
	font-size: 12px;
	font-weight: bold;
	color: ${theme.colors.gray};
`;
export const ContainerWithoutFeedBack = styled.TouchableOpacity`
	flex: 1;
	justify-content: center;
	align-items: center;
`;
export const ContainerTouch = styled.View`
	justify-content: center;
	align-items: center;
	border-width: 1px;
	border-color: ${theme.colors.white};
	width: 100%;
	height: 220px;
	background-color: ${theme.colors.white};
`;

