import { useInfiniteQuery } from "@tanstack/react-query";

import { queryKeys } from "../../services/query";
import { apiSDK } from "../../services/apiSDK";

const perPage = 20;

export const fetchCategoryData = async ({
  pageParam = 1,
  storeId,
}: {
  pageParam: number;
  storeId: number;
}) =>
  apiSDK.product.category.get({
    page: pageParam,
    perPage,
    storeId,
  });

export const fetchAdditionalData = async (
  storeId: number,
) => {
  const { data } = await apiSDK.product.soldout({
    page: 1,
    perPage,
    storeId,
  });

  return { offers: data };
};

export default function useCategories(
  storeId: number,
  auth: number | undefined
) {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    const { data, meta } = await fetchCategoryData({ pageParam, storeId });

    if (pageParam === 1) {
      const { offers } = await fetchAdditionalData(storeId);

      if (offers.length > 0) {
        data.unshift({ id: 1, is_for_legal_age: false, name: "OFERTAS" });
      }
    }

    return { data, meta };
  };

  return useInfiniteQuery({
    enabled: !!storeId,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page === lastPage.meta.last_page) {
        return undefined;
      }
      return lastPage.meta.current_page + 1;
    },
    queryFn,
    queryKey: queryKeys.CATEGORY(storeId),
    refetchOnWindowFocus: false
  });
}
