import {
	CommonActions,
	useNavigation,
	useIsFocused,
} from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import LoadingPage from "../../components/LoadingPage";

import { Header } from "../../components/Header";
import { formatDate } from "../../utils/formatDate";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useStore } from "../../hooks/use-store";
import {
	InvoiceStatus,
	PurchaseInfo,
	PurchaseResponse,
} from "../../models/purchases";
import api from "../../services/api";
import { getInvoiceStatus } from "../../utils/iugu";
import {
	BasicInfo,
	CenterContent,
	Container,
	LineContainer,
	LineText,
	NoStoreMessage,
	PaymentMethod,
	PurchaseContainer,
	Purchases,
	Store,
	StoreContent,
	StoreImage,
	StoreTitle,
	TitleContainer,
} from "./styles";

export const MyBuys: React.FC = () => {
	const focused = useIsFocused();
	const paramStore = useStore();
	const navigation = useNavigation();

	const [purchases, setPurchases] = useState<PurchaseInfo[]>([]);
	const [loading, setLoading] = useState(false);
	const [lastPage, setLastPage] = useState(10);

	useEffect(() => {
		const controller = new AbortController();
		loadPurchases(1, controller.signal);
		return () => {
			controller.abort();
		};
	}, [focused]);

	const loadPurchases = async (page: number, signal?: AbortSignal) => {
		if (page > lastPage) {
			return;
		}

		try {
			const pwaId = await AsyncStorage.getItem("id_pwa");
			page === 1 && setLoading(true);
			const response = await api.get<PurchaseResponse>(
				"/sales/history/pwa",
				{
					params: { page, store: paramStore.storeId, pwaId },
					signal: signal,
				}
			);

			const { current_page, last_page } = response.data.meta;
			setLastPage(last_page);
			page === 1
				? setPurchases(response.data.data)
				: setPurchases([...purchases, ...response.data.data]);

			setLoading(false);
		} catch (error: any) {
			if (error.message === "canceled") {
				return;
			}
			setLoading(false);
		}
	};

	const getFormattedDate = (rawDateHour: string) => {
		const date = formatDate(new Date(rawDateHour));
		const hour = rawDateHour.match(/\d\d:\d\d:\d\d/);
		return date + " - " + hour?.[0];
	};

	const handleNavigateTo = (
		screenName: string,
		params: Object | undefined = undefined
	) => {
		navigation.dispatch(
			CommonActions.navigate(
				params
					? {
							name: screenName,
							params,
					  }
					: {
							name: screenName,
					  }
			)
		);
	};

	const getColorStatus = (status: InvoiceStatus) => {
		if (status === "canceled") return "red";
		if (status === "paid") return "green";
		if (status === "paid_under_validation") return "green";
		return "yellow";
	};

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<>
			<Header title="Minhas Compras" />
			<Container>
				{purchases.length === 0 ? (
					<CenterContent testID="no_content">
						<NoStoreMessage>Nenhuma compra</NoStoreMessage>
					</CenterContent>
				) : (
					<Purchases showsVerticalScrollIndicator={false}>
						{purchases.map((purchase) => (
							<PurchaseContainer key={purchase.id}>
								<Store>
									<LineContainer marginTop={10}>
										<LineText
											color="tertiary"
											testID={`date_${purchase.id}`}
										>
											{getFormattedDate(
												purchase.created_at
											)}
										</LineText>
										<LineText
											color={getColorStatus(
												purchase.status
											)}
											fontWeight="bold"
											style={{ marginRight: 5 }}
											testID={`status_${purchase.id}`}
										>
											{getInvoiceStatus(
												purchase.status
											) || purchase.status}
										</LineText>
									</LineContainer>
									<StoreContent
										onPress={() =>
											handleNavigateTo(
												"ComprovantePagamento",
												{
													purchaseId: purchase.id,
													store: purchase.store,
													isProofPurchase: false,
													isPixPurchase: false,
													saleId: purchase.id,
													paymentMethod: purchase.payment_method,
												}
											)
										}
										testID={`btn_details_${purchase.id}`}
									>
										<StoreImage
											source={{
												uri: purchase.store.logo,
											}}
											testID={`image_${purchase.id}`}
										/>

										<BasicInfo>
											<TitleContainer>
												<StoreTitle
													ellipsizeMode="tail"
													numberOfLines={1}
													testID={`name_${purchase.id}`}
												>
													{purchase.store.name}
												</StoreTitle>
											</TitleContainer>

											<PaymentMethod
												testID={`payment_${purchase.id}`}
											>
												{purchase.store.address.street}
											</PaymentMethod>
										</BasicInfo>
									</StoreContent>
								</Store>
							</PurchaseContainer>
						))}
					</Purchases>
				)}
			</Container>
		</>
	);
};
