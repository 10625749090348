import React, { useEffect, useState } from "react";
import { Category, Product as ProductService } from "@inhouse-market/sdk";
import useProducts from "../../store/products/useProducts";
import { FlatList } from "react-native";
import { BaseClass } from "../../components/BaseClass";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useCallback, useMemo } from "react";
import { useCart } from "../../hooks/use-cart";
import { Product } from "../../components/Product";
import { Store as StoreInterface } from "../../models/store";

interface ProductListParams {
  category: Category.CategoryItem;
  storeId: number;
  store: StoreInterface;
}

type Navigation = NavigationProp<any>;

const ProductClass = (params: ProductListParams) => {
  const [selectedProduct, setSelectedProduct] = useState<Array<number>>([]);

  /* Local Storage */
  const cart = useCart();
  const cartItems = useMemo(() => cart.getCart(), [cart]);

  const navigation = useNavigation<Navigation>();

  const { data: dataProductList } = useProducts(
    params.storeId,
    params.category.id,
    params.category.name
  );

  const productsList = useMemo<ProductService.PlanogramItem[]>(() => {
    if (!dataProductList) {
      return [];
    }

    return dataProductList?.pages.flatMap((page) => page.data) || [];
  }, [dataProductList, cartItems]);

  const keyExtractor = useCallback(
    (item: ProductService.PlanogramItem, i: number) => `${i}-${item.id}`,
    []
  );

  const handleNavigateToScreen = (
    screenName: string,
    params: Object | undefined = undefined
  ) => {
    navigation.navigate(screenName, {
      params,
    });
  };

  const handleSelectedIds = (plan: ProductService.PlanogramItem) => {
    let data = [...selectedProduct];
    if (data.includes(plan.id)) {
      data = data.filter((item) => item != plan.id);
    } else {
      data.push(plan.id);
    }
    setSelectedProduct(data);
  };

  const rebindFunction = (plan: ProductService.PlanogramItem, e: number) => {
    handleChangeQuantity(plan, e);
  };

  const handleChangeQuantity = async (
    plan: ProductService.PlanogramItem,
    qnty: number
  ) => {
    const cartItem = cartItems.find((item) => item.id === plan?.id);
    const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
    cart.updateCartItem(plan, actualQnty, false, false, false, params.store);
  };

  const verifySelectedProds = React.useCallback(async () => {
    setSelectedProduct((selected) => {
      return selected.filter((item) => cart.cartIds.includes(item));
    });
  }, [cart.cartIds]);

  const seeMoreAction = () => {
    handleNavigateToScreen("Categorias", {
      name: params.category.name,
      id: params.category.id,
    });
  };

  useEffect(() => {
    verifySelectedProds();
  }, [verifySelectedProds]);

  return (
    <BaseClass
      title={productsList.length > 0 ? params.category.name : ""}
      seeMoreAction={productsList.length > 0 ? seeMoreAction : undefined}
    >
      <FlatList
        data={productsList}
        keyExtractor={keyExtractor}
        horizontal
        renderItem={({ index, item: product }) => (
          <Product
            key={index}
            onPress={() => handleNavigateToScreen("Product", { product })}
            imgUrl={product?.product?.image_url}
            onQuantityChange={(e) => rebindFunction(product, e)}
            onQuantityOpen={() => handleSelectedIds(product)}
            onQuantityPress={() => {}}
            price_list={parseFloat(`${product?.price_list_in_cents}`) / 100}
            quantity={
              cartItems.find((item) => item.id === product.id)?.quantity || 0
            }
            title={product?.product?.name}
            price_final={parseFloat(`${product?.price_final_in_cents}`) / 100}
            quantityOpen={
              (cartItems.find((item) => item.id === product.id)?.quantity ||
                0) >= 1
            }
            style={{
              marginRight: 14,
            }}
            maxQuantity={product.stock_amount}
            testID={product.id.toString()}
          />
        )}
      />
    </BaseClass>
  );
};

export default ProductClass;
