import React, { useState, useEffect, useRef, ReactFragment } from 'react';
import { Animated, Easing } from 'react-native';
import { ModalContext } from '../contexts';
import { sleep } from '../utils/sleep';
import { Modal } from './components/modal';

export const ModalProvider = ({ children }: React.PropsWithChildren) => {
  const [modalChildren, setModalChildren] = useState<ReactFragment>();
  const [showModal, setShowModal] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const toggleAnimation = async (show: boolean) => {
    if (show) {
      setShowModal(true);
    } else {
      Animated.timing(animation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
        easing: Easing.ease,
      }).start();
      await sleep(200);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal)
      Animated.timing(animation, {
        toValue: 100,
        duration: 200,
        useNativeDriver: false,
        easing: Easing.ease,
      }).start();
  }, [showModal]);

  const show = async (content: ReactFragment): Promise<void> => {
    setModalChildren(content);
    await toggleAnimation(true);
  };

  const close = async (): Promise<void> => {
    await toggleAnimation(false);
  };

  return (
    <ModalContext.Provider value={{ show, close }}>
      {children}
      <Modal show={showModal} animation={animation} close={close}>
        {modalChildren}
      </Modal>
    </ModalContext.Provider>
  );
};
