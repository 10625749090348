import {
  NavigationProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import React, { useEffect, useMemo, useState } from "react";
import { useCart } from "../../hooks/use-cart";
import { format } from "date-fns";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import {
  ButtonPaymentContainer,
  ChangeButton,
  ConfirmButton,
  Container,
  ContainerContentEmptyCart,
  Content,
  ContentButtonProducts,
  ContentEmptyCart,
  EmptyCartContainer,
  EmptyCartIcon,
  NoItemsText,
  PayAtContainer,
  PixDetailsContainer,
  PixInfo,
  PixLogo,
  Scroll,
  TextChange,
  TextConfirmButton,
  Value,
  ValueSecondary,
  Subtitle,
  ContentPixMessage,
  WarningContainer,
  WarningIcon,
  WarningText,
  NoticeContainer,
  NoticeText,
} from "./styles";
import HeaderNavigation from "../../components/HeaderNavigation";
import { SallesResponseType } from "../../store/payments/payment.types";
import ModalPayments from "../../components/ModalPayments";
import ModalSupport from "../../components/ModalSupport";

import {
  subscribeToEvent, unsubscribeFromEvent,
} from '../../services/socket';
import { SOCKET_COMMANDS } from "../../utils/socketCommands";

const CartEmpty = require("../../assets/cart_clean.svg");

interface PropsPayments {
  expires_at: string | null;
  url: string | null;
}

export interface Receipt {
  purchaseId: number | null;
  saleId: number | undefined;
  success: boolean;
}

type Navigation = NavigationProp<any>;

const Payment: React.FC = () => {
  const route = useRoute();
  const initialParams = route.params as SallesResponseType;
  const [purchaseId, setPurchaseId] = useState<string | null>(null);
  const [saleId, setSaleId] = useState<number>();
  const [data, setData] = useState<PropsPayments>({
    expires_at: null,
    url: null,
  });
  const myCart = useCart();
  const navigation = useNavigation<Navigation>();
  const [flowPaymentModal, setFlowPaymentModal] = useState("");
  const [open, setOpen] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [receipt, setReceipt] = useState<Receipt>({} as Receipt);

  const cartItems = useMemo(() => myCart.getCart(), [myCart]);

  const handleNavigateTo = () => {
    navigation.navigate("Carrinho");
  };

  useEffect(() => {
    if (initialParams?.invoiceId) {
      setPurchaseId(initialParams.invoiceId);
      setSaleId(initialParams.saleId);
      setData(initialParams);
      myCart.setIsAlreadyCreated(true);
    }
  }, [initialParams]);

  useEffect(() => {
    const getPixPayment = () => {
      subscribeToEvent(SOCKET_COMMANDS.PAYMENT(initialParams.saleId), (data:
        { saleId: number, status: 'paid' | "canceled" | "expired" }) => {

        const { saleId } = route.params as SallesResponseType;

        if (saleId !== data.saleId) return;

        if (data.status === 'canceled' || data.status === 'expired') {
          setReceipt({
            purchaseId: saleId,
            saleId,
            success: false,
          });
          setFlowPaymentModal("failed");
          setOpen(true);

          setPurchaseId(null);
          myCart.clearCart(false);
          setData({ expires_at: null, url: null });
        }
        if (data.status === 'paid') {
          setReceipt({
            purchaseId: saleId,
            saleId,
            success: true,
          });
          setFlowPaymentModal("success");
          setOpen(true);

          setPurchaseId(null);
          myCart.clearCart(false);
          setData({ expires_at: null, url: null });
        }
      });

      return () => {
        unsubscribeFromEvent(SOCKET_COMMANDS.PAYMENT(initialParams.saleId));
      };
    }
    getPixPayment()
  }, [route?.params]);

  useEffect(() => {
    if (data.url !== null) {
      navigation.setOptions({ tabBarStyle: { display: "none" } });
      return;
    }
    navigation.setOptions({ tabBarStyle: { display: "flex", height: 80 } });
  }, [data]);

  return (
    <>
      {open && (
        <ModalPayments
          isOpen={open}
          setOpen={setOpen}
          flow={flowPaymentModal}
          setFlow={setFlowPaymentModal}
          receipt={receipt}
        />
      )}
      {showSupport && (
        <ModalSupport isOpen={showSupport} setOpen={setShowSupport} />
      )}
      <Container>
        <HeaderNavigation
          enableButtonLeft
          enableButtonRight={false}
          title="Pagamento por PIX"
        />
        {cartItems.length === 0 && !data.expires_at ? (
          <EmptyCartContainer>
            <ContainerContentEmptyCart>
              <ContentEmptyCart>
                <EmptyCartIcon source={CartEmpty} />
                <NoItemsText>
                  Parece que você ainda não comprou nada
                </NoItemsText>
              </ContentEmptyCart>
              <ContentButtonProducts>
                <ConfirmButton onPress={handleNavigateTo}>
                  <TextConfirmButton>VER PRODUTOS</TextConfirmButton>
                </ConfirmButton>
              </ContentButtonProducts>
            </ContainerContentEmptyCart>
          </EmptyCartContainer>
        ) : (
          <Content>
            <Scroll showsVerticalScrollIndicator={false}>
              <PayAtContainer>
                <ValueSecondary>Total: </ValueSecondary>
                <ValueSecondary testID="limit_date">
                  {NumberToCurrency(
                    cartItems.reduce(
                      (all, curr) =>
                        all + (curr.final_price_in_cents / 100) * curr.quantity,
                      0
                    )
                  )}
                </ValueSecondary>
              </PayAtContainer>
              <Subtitle>
                Pague até:{" "}
                {data?.expires_at &&
                  format(new Date(data.expires_at), "dd/MM 'às' HH:mm")}
              </Subtitle>
              <ContentPixMessage>
                <Value testID="pix_value">PIX QR CODE</Value>
                <PixInfo>Leia no app do seu banco</PixInfo>
              </ContentPixMessage>
              <PixDetailsContainer>
                <PixLogo source={{ uri: data.url ?? "" }} testID="image" />
              </PixDetailsContainer>
              <WarningContainer>
                <WarningIcon color={"#B0B0B0"} />
                <WarningText>Não leve os produtos sem pagar</WarningText>
              </WarningContainer>
              <NoticeContainer>
                <NoticeText>
                  Quer pagar com cartão de crédito? <br /> Baixe o app SHOPPBUD
                  na app store
                </NoticeText>
              </NoticeContainer>
            </Scroll>
            <ButtonPaymentContainer>
              <>
                <ChangeButton onPress={handleNavigateTo}>
                  <TextChange>Voltar ao carrinho</TextChange>
                </ChangeButton>
              </>
            </ButtonPaymentContainer>
          </Content>
        )}
      </Container>
    </>
  );
};

export default Payment;
