import styled, { css } from "styled-components/native";
import { theme } from "../../../global/theme";

interface OverlayProps {
  mode: string;
}

export const Container = styled.View`
  align-items: center;
  background: #fff;
  border-radius: 24px;
  border-width: 0px;
  border-color: transparent;
  width: 100%;
  max-width: 562px;
  height: 100%;
  max-height: 820px;
`;

export const Content = styled.View`
  width: 100%;
  align-items: center;
  flex: 1;
`;

export const ViewHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: 23px;
  font-weight: 700;
  font-family: ${theme.fonts.bold};
  margin-top: 27px;
  text-align: center;
  color: #000000;
`;

export const ChangeClose = styled.TouchableOpacity`
  position: absolute;
  right: 0;
`;

export const ImageClose = styled.Image`
  width: 35.74px;
  height: 35.74px;
  margin-right: 19px;
`;

export const Overlay = styled.View<OverlayProps>`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;

  ${(props) =>
    props.mode === "success" &&
    css`
      background-color: #20ac5494;
    `}

  ${(props) =>
    props.mode === "failed" &&
    css`
      background-color: #ff000078;
    `}
`;

export const TextInformationContainer = styled.View`
  width: 100%;
  max-width: 341.33px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
`;

export const TextInformation = styled.Text`
  font-size: 21.79px;
  line-height: 31.02px;
  font-weight: 400;
  font-family: ${theme.fonts.regular};
  text-align: center;
  color: #000000;
`;

export const ViewButton = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 11;
  width: 100%;
  position: absolute;
  bottom: 27px;
`;

export const ConfirmButton = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  justify-content: center;
  border-width: 3.21px;
  border-color: ${theme.colors.primary};
  padding: 17px;
  width: 45%;
  height: 100px;
`;

export const ChangeButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
  border-width: 3.21px;
  border-color: ${theme.colors.primary};
  border-radius: 8px;
  border-color: ${theme.colors.primary};
  padding: 17px;
  width: 45%;
  height: 100px;
`;

export const TextChange = styled.Text`
  font-size: 14px;
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.primary};
  text-align: center;
  font-size: 23px;
`;

export const TextConfirmButton = styled.Text`
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.white};
  text-align: center;
  font-size: 23px;
`;

export const TextDownloadApp = styled.Text`
  width: 271px;
  height: 52px;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  line-height: 25.63px;
  font-family: ${theme.fonts.medium};
`;

export const TextAndroidIos = styled.Text`
  font-family: ${theme.fonts.regular};
  width: 341.33px;
  height: 26px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #b0b0b0;
`;

export const ViewInformationApp = styled.View`
  margin-top: 50px;
  align-items: center;
`;

export const ImageSuccess = styled.Image`
  width: 246px;
  height: 246px;
  margin-top: 107px;
`;

export const ImageLogo = styled.Image`
  width: 284px;
  height: 45px;
  margin-top: 12px;
  margin-bottom: 8px;
`;
