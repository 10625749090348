import styled from 'styled-components/native';
import { theme } from '../../global/theme';
import { TouchableOpacity } from 'react-native';


export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;

export const ModalArea = styled.View`
  width: 100%;
  max-width: 358px;
  min-height: 410px;
  background: #ffffff;
  border-radius: 10px;
`
export const ModalAreaHeader = styled.View`
  width: 100%;
  height: 44px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  align-items: flex-end;
  justify-content: center;
`
export const IconCloseModal = styled.TouchableOpacity`
  width: 37px;
  height: 37px;
  align-items: center;
  justify-content: center;
  margin: 8px 8px 0px 0px;
`

export const CloseIcon = styled.Image`
  width: 14px;
  height: 14px;
`

export const ModalContent = styled.View`
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
`

export const ModalContentIconMail = styled.Image`
  width: 98px;
  height: 90px;
`

export const ModalLabelInput = styled.Text`
  font-size: 18px;
  color: #000000;
  font-family: ${theme.fonts.regular};
  margin-top: 33px;
  text-align: center;
`

export const InputContentMail = styled.View<{ isFocused: boolean }>`
  width: 100%;
  max-width: 319px;
  height: 55px;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px 0px 0px 17px;
  margin-top: 20px;
  border-color: ${props => props.isFocused ? `${theme.colors.primary}` : '#e2e2e2'};
  position: relative;
`
export const InputLabel = styled.Text`
  font-size: 12px;
  font-family: ${theme.fonts.regular};
  color: #b7b9c0;
`

export const InputLabelMandatory = styled.Text`
  font-size: 12px;
  color: ${theme.colors.primary};
`

export const InputTextArea = styled.TextInput`
  border: 0px;
  outlineStyle: none;
`

type ButtonProps = {
  disabled?: boolean;
  onPress: () => void;
};

export const ButtonSend = styled(TouchableOpacity)<ButtonProps>`
  width: 100%;
  max-width: 244px;
  height: 39px;
  background-color: ${theme.colors.primary};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
  opacity: ${props => props.disabled ? '0.3' : '1'};
  outlineStyle: none;
`
export const ButtonSendText = styled.Text`
  font-size: 14px;
  font-family: ${theme.fonts.semiBold};
  color: ${theme.colors.white};
`

export const ErrorMessage = styled.Text`
  color: ${theme.colors.red};
  font-size: 12px;
  font-family: ${theme.fonts.regular};
  position: absolute;
  bottom: -20px;
`

export const MessageSuccess = styled.Text`
  font-size: 18px;
  color: #000000;
  font-family: ${theme.fonts.regular};
  margin-top: 29px;
  text-align: center;
  line-height: 25.63px;
  border-bottom-width: 0.8px;
  border-color: #EDEDED;
  padding-bottom: 23px;
`

export const WarningMessage = styled.Text`
  font-size: 18px;
  color: #000000;
  font-family: ${theme.fonts.regular};
  margin-top: 29px;
  text-align: center;
  line-height: 25.63px;
`
export const ShoppbudLogo = styled.Image`
  width: 100%;
  max-width: 222px;
  height: 73px;
`