import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ActivityIndicator, Dimensions } from "react-native";
import { TypePaymentMethod } from "../../../store/payments/payment.types";
import { NumberToCurrency } from "../../../utils/numberToCurrency";
import {
  Container,
  Content,
  PurchaseDetails,
  TotalContainerContent,
  TotalContainerContentTextValue,
  TotalContainerContentValue,
  ViewWrapper,
  ViewFlex,
  CardContainerIcon,
  ViewSelectCards,
  TextContainer,
  PressableFinish,
  HeaderLink,
  Title,
  ViewContainer,
  TouchableOpacityBtn,
  ImageCard,
  ImagePix,
  ContainerPressable,
} from "./styles";
import { useCart } from "../../../hooks/use-cart";
import { useGetPaymentMethod } from "../../../store/payments/payment-methods";
import { useStore } from "../../../hooks/use-store";
import { useShellData } from "../../../providers/shell-provider";

export interface ModalPropsMethod {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPayment?: (value: string) => void;
  selectedPayment?: string;
  onFinished?: (value: string) => void;
  loading?: boolean;
}

export default function MethodsPayments({
  setOpen,
  selectedPayment,
  setSelectedPayment,
  onFinished,
  loading,
}: ModalPropsMethod) {
  const myCart = useCart();
  const paramStore = useStore();
  const cartItems = useMemo(() => myCart.getCart(), [myCart]);
  const [isSmallDevice, setIsSmallDevice] = useState(
    Dimensions.get("window").width
  );

  const { data: methodPaymentEnabled } = useGetPaymentMethod(
    paramStore.storeId
  );

  const shellData = useShellData();

  useEffect(() => {
    const updateDimensions = () => {
      setIsSmallDevice(Dimensions.get("window").width);
    };

    Dimensions.addEventListener("change", updateDimensions);
  }, []);

  const methodsPayments = [
    {
      type: "PIX",
      value: TypePaymentMethod.PIX,
      enabled: true,
    },
    {
      type: "Cartão de crédito",
      value: TypePaymentMethod.PLUGPAG_CREDIT,
      enabled: false,
    },
    {
      type: "Cartão de débito",
      value: TypePaymentMethod.PLUGPAG_DEBIT,
      enabled: false,
    },
    {
      type: "Voucher",
      value: TypePaymentMethod.PLUGPAG_VOUCHER,
      enabled: false,
    },
  ];

  const hasPlugpagMethod = methodPaymentEnabled?.payments?.some(
    (item) =>
      item.name === TypePaymentMethod.PLUGPAG_DEBIT ||
      item.name === TypePaymentMethod.PLUGPAG_CREDIT ||
      item.name === TypePaymentMethod.PLUGPAG_VOUCHER
  );

  if (shellData?.pos?.paired && shellData?.pos?.nearby && hasPlugpagMethod) {
    methodPaymentEnabled?.payments.forEach((item) => {
      if (item.is_active && item.is_global_active) {
        if (item.name === TypePaymentMethod.PLUGPAG_CREDIT)
          methodsPayments[1].enabled = true;
        if (item.name === TypePaymentMethod.PLUGPAG_DEBIT)
          methodsPayments[2].enabled = true;
        if (item.name === TypePaymentMethod.PLUGPAG_VOUCHER)
          methodsPayments[3].enabled = true;
      }
    });
  }

  const onFinishPayment = (value: string) => {
    if (setSelectedPayment) {
      setSelectedPayment(value);
    }
    if (onFinished) {
      onFinished(value);
    }
  };
  return (
    <Container>
      <Title testID="modal_title">Forma de pagamento</Title>
      <Content>
        <PurchaseDetails>
          <TotalContainerContent>
            <TotalContainerContentTextValue>
              Total ({myCart.itemsTotal} un)
            </TotalContainerContentTextValue>
          </TotalContainerContent>
          <TotalContainerContentValue>
            <TotalContainerContentTextValue>
              {NumberToCurrency(
                cartItems.reduce(
                  (all, curr) =>
                    all + (curr.final_price_in_cents / 100) * curr.quantity,
                  0
                )
              )}
            </TotalContainerContentTextValue>
          </TotalContainerContentValue>
        </PurchaseDetails>

        <ViewContainer>
          {methodsPayments.filter((method) => method.enabled).map((method, index) => {
            return (
              <TouchableOpacityBtn
                key={`${index}`}
                onPress={() => onFinishPayment(method.value)}
                enabled={method.enabled}
                disabled={loading || method.enabled === false}
              >
                <ViewWrapper
                  selected={selectedPayment === method.value}
                  isSmallDevice={isSmallDevice}
                >
                  <ViewFlex>
                    <CardContainerIcon>
                      {method.value !== TypePaymentMethod.PIX ? (
                        <ViewSelectCards>
                          <ImageCard
                            source={require("../../../assets/Cartão.svg")}
                          />
                        </ViewSelectCards>
                      ) : (
                        <ViewSelectCards>
                          <ImagePix
                            source={require("../../../assets/pix.svg")}
                          />
                        </ViewSelectCards>
                      )}
                    </CardContainerIcon>
                    <TextContainer>
                      {method.type}
                      {!method.enabled && " (indisponivel)"}
                    </TextContainer>
                  </ViewFlex>
                </ViewWrapper>
              </TouchableOpacityBtn>
            );
          })}
        </ViewContainer>
      </Content>

      {loading && <ActivityIndicator color={"#FF7700"} size={"large"} />}

      <ContainerPressable>
        <PressableFinish
          onPress={() => setOpen(false)}
          testID="modal_finish_button"
        >
          <HeaderLink>Cancelar</HeaderLink>
        </PressableFinish>
      </ContainerPressable>
    </Container>
  );
}
