import React, { useEffect, useState } from 'react'
import { Modal as ReactNativeModal } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NavigationProp, useNavigation } from "@react-navigation/native";
import {
  Overlay,
  ModalArea,
  ModalAreaHeader,
  IconCloseModal,
  CloseIcon,
  ModalContentIconMail,
  ModalContent,
  ModalLabelInput,
  InputContentMail,
  InputLabel,
  InputLabelMandatory,
  InputTextArea,
  ButtonSend,
  ButtonSendText,
  ErrorMessage,
  MessageSuccess,
  WarningMessage,
  ShoppbudLogo
} from './styles';
import { theme } from '../../global/theme';
import { useSendEmailMutation } from '../../store/EmailSent';
import { useTimer } from '../../hooks/useTimer';

interface SendReceiptProps {
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  saleId: number
}

type Navigation = NavigationProp<any>;

const ModalSendReceipt: React.FC<SendReceiptProps> = ({ isOpen, setOpen, saleId }) => {

  const [isFocused, setIsFocused] = useState(false);

  const navigation = useNavigation<Navigation>();

  const sendMailSchema = Yup.object({
    email: Yup.string().required('Informe um email').email('Email inválido')
  })

  const { isLoading, isError, isSuccess, isIdle, reset, mutate } = useSendEmailMutation()

  const handleSendEmail = async (values: { email: string }) => {
    mutate({ email: values.email, saleId });
  }

  const timer = useTimer(() => {
    window.location.reload(); //reload page
  }, 15 * 1000); // 15 seconds

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <ReactNativeModal transparent={true} visible={isOpen} animationType="slide">
      <Overlay>
        <ModalArea>
          <ModalAreaHeader>
            <IconCloseModal onPress={() => {
              setOpen(false)
              reset()
            }}>
              <CloseIcon source={require('../../../assets/close.svg')} />
            </IconCloseModal>
          </ModalAreaHeader>
          <ModalContent>
            {(isIdle || isLoading) &&
              <>
                <ModalContentIconMail source={require('../../../assets/mail.svg')} />
                <ModalLabelInput>
                  Enviar comprovante por e-mail
                </ModalLabelInput>
                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={sendMailSchema}
                  onSubmit={(values) => {
                    timer.set(15 * 1000);  //restart timer
                    handleSendEmail(values);
                  }}
                >
                  {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                    <>
                      <InputContentMail isFocused={isFocused} style={{ borderColor: errors.email && `${theme.colors.red}` }}>
                        <InputLabel>
                          Email
                          <InputLabelMandatory>*</InputLabelMandatory>
                        </InputLabel>
                        <InputTextArea
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => {
                            handleBlur('email')
                            setIsFocused(false)
                          }}
                          editable={!isLoading}
                          onChangeText={(text) => {
                            handleChange('email')(text);
                            timer.set(15 * 1000);  //restart timer
                          }}
                          value={values.email}
                        />
                        {errors.email &&
                          <ErrorMessage>
                            {errors.email}
                          </ErrorMessage>
                        }
                      </InputContentMail>
                      <ButtonSend onPress={() => handleSubmit()} disabled={isLoading}>
                        <ButtonSendText>
                          {isLoading ? 'Aguarde...' : 'Enviar'}
                        </ButtonSendText>
                      </ButtonSend>
                    </>
                  )}
                </Formik>
              </>
            }
            {isSuccess &&
              <>
                <ModalContentIconMail source={require('../../../assets/mail-check.svg')} />
                <MessageSuccess>
                  Comprovante enviado!
                  Verifique sua caixa de e-mail
                </MessageSuccess>
                <WarningMessage>
                  Em sua próxima compra use nosso aplicativo
                </WarningMessage>
                <ShoppbudLogo source={require('../../../assets/shoppbud.svg')} />
                <ButtonSend style={{ marginBottom: 47, marginTop: 50 }} onPress={() => navigation.navigate("Loja")}>
                  <ButtonSendText>
                    Voltar ao início
                  </ButtonSendText>
                </ButtonSend>
              </>
            }
            {isError &&
              <>
                <ModalContentIconMail source={require('../../../assets/mail_error.svg')} />
                <MessageSuccess>
                  Desculpe, não conseguimos enviar o comprovante.
                </MessageSuccess>
                <WarningMessage>
                  Por favor, verifique seu email e tente novamente.
                </WarningMessage>
                <ButtonSend style={{ marginBottom: 47, marginTop: 50 }} onPress={() => navigation.navigate("Loja")}>
                  <ButtonSendText>
                    Continue comprando
                  </ButtonSendText>
                </ButtonSend>
              </>
            }
          </ModalContent>
        </ModalArea>
      </Overlay>
    </ReactNativeModal>
  )
}

export default ModalSendReceipt