import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.TouchableOpacity`
	border-radius: 10px;
	border: 1px solid ${theme.colors.border};
	flex-direction: row;
	padding: 10px 10px;
`;

export const ImageContainer = styled.View`
	height: 90px;
	width: 90px;
	position: relative;
	flex-direction: row;
`;

export const Image = styled.Image`
	height: 90px;
	width: 90px;
	border: 1px solid #efefef;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	border-radius: 5px;
`;

export const DiscountIcon = styled.Image`
	position: absolute;
	top: 8px;
	left: 8px;
	width: 20px;
	height: 20px;
	fill: ${theme.colors.primary};
`;

export const QuantityContainer = styled.View`
	position: absolute;
	bottom: 4px;
	right: 0;
`;

export const DescriptionContainer = styled.View`
	padding: 8px;
	position: relative;
	border-top-color: ${theme.colors.border};
	flex: 1;
`;

export const Title = styled.Text`
	font-size: 14px;
	font-family: ${theme.fonts.regular};
`;

interface PriceTextProps {
	isDiscount: boolean;
}

export const PriceText = styled.Text<PriceTextProps>`
	position: absolute;
	bottom: 8px;
	font-size: 16px;
	font-family: ${theme.fonts.semiBold};
	color: ${({ isDiscount }) =>
		isDiscount ? theme.colors.primary : theme.colors.black};
`;

export const RiskedPrice = styled.Text`
	font-size: 13px;
	position: absolute;
	left: 8px;
	bottom: 25px;
	text-decoration: line-through;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.gray};
`;

export const ContainerMessage = styled.View`
	position: absolute;
	background-color: red;
	padding: 5px;
	text-align: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;

export const Message = styled.Text`
	color: white;
	font-size: 10px;
	font-weight: 500;
`;
