import styled, { css } from "styled-components/native";
import { theme } from "../../../global/theme";

interface OverlayProps {
  mode: string;
}

export const Container = styled.View`
  align-items: center;
  background: #fff;
  border-radius: 24px;
  border-width: 0px;
  border-color: transparent;
  width: 100%;
  min-width: 200px;
  max-width: 562px;
  height: 100%;
  max-height: 820px;
`;

export const Content = styled.View`
  width: 100%;
  border-top-width: 2px;
  border-top-color: #f6f6f6;
  align-items: center;
  flex: 1;
`;

export const Title = styled.Text`
  font-size: 23px;
  font-weight: 700;
  font-family: ${theme.fonts.bold};
  margin-top: 23px;
  margin-bottom: 29px;
  text-align: center;
  color: #000000;
`;

export const Description = styled.Text`
  font-size: 13px;
  font-family: ${theme.fonts.regular};
  margin-bottom: 30px;
  text-align: center;
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #949494;
  width: 100%;
`;

export const TextButton = styled.Text`
  color: #2081ff;
  font-size: 14px;
  font-family: ${theme.fonts.bold};
`;
export const IconAlert = styled.Image`
  width: 64px;
  height: 64px;
`;
export const IconContaine = styled.View`
  margin-top: 30px;
`;

export const Overlay = styled.View<OverlayProps>`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;

  ${(props) =>
    props.mode === "success" &&
    css`
      background-color: #20ac5494;
    `}

  ${(props) =>
    props.mode === "failed" &&
    css`
      background-color: #ff000078;
    `}
`;

export const PressableFinish = styled.Pressable`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 44px;
`;

export const ViewRadio = styled.View`
  width: 20px;
  height: 20px;
  border-color: black;
  border-radius: 10px;
  border-width: 1px;
  margin: 10px;
  justify-content: center;
  align-items: center;
`;

export const ViewRadioBg = styled.View`
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 10px;
`;

export const TotalContainerContentText = styled.Text`
  font-family: ${theme.fonts.regular};
  font-size: 15px;
  color: ${theme.colors.primary};
  text-align: center;
`;

export const HeaderLink = styled.Text`
  font-size: 15px;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  text-decoration-color: ${theme.colors.primary};
`;

export const TextInformationContainer = styled.View`
  width: 100%;
  max-width: 341.33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
`;

export const TextInformation = styled.Text`
  font-size: 25px;
  line-height: 31.02px;
  font-weight: 400;
  font-family: ${theme.fonts.regular};
  text-align: center;
  color: #000000;
`;

export const ViewButton = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 11;
  position: absolute;
  bottom: 27px;
`;

export const ViewHeader = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ChangeClose = styled.TouchableOpacity`
  position: absolute;
  right: 0;
`;

export const ConfirmButton = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  justify-content: center;
  border-width: 3.21px;
  border-color: ${theme.colors.primary};
  padding: 17px;
  width: 45%;
  max-height: 100px;
`;

export const TextConfirmButton = styled.Text`
  font-size: 23px;
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.white};
  text-align: center;
`;

export const ChangeButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
  border-width: 3.21px;
  border-color: ${theme.colors.primary};
  border-radius: 8px;
  border-color: ${theme.colors.primary};
  padding: 17px;
  width: 45%;
  height: 100px;
`;

export const TextChange = styled.Text`
  font-size: 23px;
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.primary};
  text-align: center;
  text-wrap: balance;
`;

export const ImageClose = styled.Image`
  width: 35.74px;
  height: 35.74px;
  margin-right: 19px;
`;

export const ImageFailed = styled.Image`
  width: 246px;
  height: 246px;
  margin-top: 80px;
`;
