export const queryKeys = {
  CATEGORY: (storeId: number) => ["CATEGORY", storeId],
  ADVERTS: (showIn: "INSIDESTORE" | "OUTSTORE" | "SLEEPSCREEN", storeId: string | number | null | undefined) => ['ADVERTS', showIn, storeId],
  PRODUCTS: (storeId: number, categoryId: number, name: string) => [
    "PRODUCTS",
    storeId,
    categoryId,
    name,
  ],
  STORES: (latitude: number, longitude: number, name?: string) => [latitude, longitude, name]
};
