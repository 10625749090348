import styled from "styled-components/native";
import { Feather } from "@expo/vector-icons";
import { theme } from "../../global/theme";

export const Scroll = styled.ScrollView``;

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
	padding: 20px;
`;

export const PaymentDetailsContainer = styled.View`
	background-color: ${theme.colors.white};
	align-items: center;
	justify-content: space-between;
	padding: 20px 54px;
	margin-bottom: 4px;
`;

export const PixValueContainer = styled.View`
  display: flex;
	justify-content: center;
	flex-direction: row;
	margin-bottom: 26px;
	margin-top: 41px;
`;

export const Title = styled.Text`
	text-align: center;
	padding-bottom: 4px;
	font-size: 16px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.anotherGray};
`;

export const Subtitle = styled.Text`
	text-align: center;
	padding-bottom: 4px;
	font-size: 14px;
	font-family: ${theme.fonts.regular};
	color: #000000;
	margin-bottom: 30px;
`;

export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.tertiary};
	font-size: 16px;
`;

export const ValueSecondary = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.secondary};
	font-size: 20px;
`;

export const PayAtContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	border-width: 1px;
	margin-bottom: 26px;
	border-color: #ededed;
	height: 31px;
	padding-vertical: 8px;
	padding-horizontal: 10px;
`;

export const PixDetailsContainer = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border-width: 1px;
	border-color: #ededed;
	margin-bottom: 26px;
	padding: 20px;
`;

export const PixLogo = styled.Image`
	width: 240px;
	height: 240px;
`;

export const PixInfo = styled.Text`
	text-align: center;
	color: #000000;
	font-size: 16px;
	padding-bottom: 32px;
	font-family: ${theme.fonts.regular};
`;

export const PixCodeContainer = styled.View`
	width: 100%;
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	border-color: #ededed;
	padding-vertical: 8px;
	padding-horizontal: 10px;
	margin-bottom: 16px;
`;

export const ButtonPaymentContainer = styled.View`
	width: 100%;
	justify-content: center;
	height: 92px;
	align-items: center;
	position: absolute;
	left: 0px;
	bottom: 0px;
	border-top-width: 1px;
	border-color: #ededed;
`;

export const Label = styled.Text`
	color: rgba(48, 55, 74, 0.35);
	font-size: 12px;
	font-family: ${theme.fonts.regular};
`;
export const PixCodeText = styled.Text.attrs({
	numberOfLines: 1,
	ellipsizeMode: "tail",
})`
	font-size: 16px;
	font-family: ${theme.fonts.regular};
`;

export const ConfirmButton = styled.TouchableOpacity`
	display: flex;
	width: 267px;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextConfirmButton = styled.Text`
	font-size: 16px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const TextAnother = styled.Text`
	font-size: 11px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
	margin-top: 10px;
`;

export const ChangeButton = styled.TouchableOpacity`
	display: flex;
	max-width: 335px;
	width: 80%;
	height: 55px;
	border-radius: 10px;
	background-color: ${theme.colors.primary};
	justify-content: center;
`;

export const TextChange = styled.Text`
	font-size: 18px;
	font-family: ${theme.fonts.semiBold};
	color: ${theme.colors.white};
	text-align: center;
`;

export const ContainerContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-between;
`;

export const ContentEmptyCart = styled.View`
	display: flex;
	height: 80%;
	align-items: center;
	justify-content: space-evenly;
`;

export const EmptyCartContainer = styled.View`
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const EmptyCartIcon = styled.Image`
	width: 210px;
	height: 210px;
`;

export const NoItemsText = styled.Text`
	width: 317px;
	font-size: 18px;
	text-align: center;
	padding-top: 20px;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.black};
`;

export const ContentButtonProducts = styled.View`
	margin-top: 80px;
`;

export const ContentPixMessage = styled.View`
	align-items: center;
	justify-content: center;
`;

export const WarningContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 14px;
`;

export const WarningIcon = styled(Feather).attrs({
	name: "alert-triangle",
	size: 15,
})`
	margin-right: 5px;
`;

export const WarningText = styled.Text`
	font-size: 14px;
	color: ${theme.colors.disabledButtonText};
	font-family: ${theme.fonts.regular};
`;

export const ButtomPrev = styled.TouchableOpacity`
  position: absolute;
	left: 0;
	top: 0;
`

export const PrevText = styled.Text`
	font-size: 15px; 
	color: ${theme.colors.primary};
	font-family: ${theme.fonts.bold};
	text-decoration: underline;
	text-decoration-color:  ${theme.colors.primary};
`
export const NoticeContainer = styled.View`
	width: 100%;
	flex-direction: row;
	justify-content: center;
	margin-top: 23px;
`

export const NoticeText = styled.Text`
	font-size: 14px;
	color: ${theme.colors.disabledButtonText};
	font-family: ${theme.fonts.regular};
	text-align: center;
	line-height: 19.94px;
`