import React, { Dispatch, SetStateAction, useEffect } from "react";

import {
  Container,
  Content,
  ConfirmButton,
  TextChange,
  TextConfirmButton,
  TextInformation,
  ChangeButton,
  TextInformationContainer,
  TextDownloadApp,
  TextAndroidIos,
  ViewButton,
  ImageSuccess,
  ImageLogo,
  ViewInformationApp,
  ViewHeader,
  Title,
  ChangeClose,
  ImageClose,
} from "./styles";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import Success from "../../../assets/success.png";
import Logo from "../../../assets/shoppbud.png";
import AudioPaymentSuccess from "../../../assets/mp3/payment-success.mp3";
import { Receipt } from "../../../pages/payment";
import ReactAudioPlayer from "react-audio-player";
import { useTimer } from "../../../hooks/useTimer";
import Close from "../../../assets/closebutton.png";

interface PropsSuccess {
  setMode: React.Dispatch<React.SetStateAction<string>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  receipt?: Receipt;
}

type Navigation = NavigationProp<any>;

export default function PaymentSuccess({
  setMode,
  setOpen,
  receipt,
}: PropsSuccess) {
  setMode("success");

  const navigation = useNavigation<Navigation>();

  const handleNavigateTo = () => {
    setOpen(false);
    navigation.navigate("Loja");
  };

  const handleReceipt = () => {
    if (receipt) {
      navigation.navigate("ComprovantePagamento", {
        purchaseId: receipt?.purchaseId,
        saleId: receipt?.saleId,
      });
      setOpen(false);
    }
  };

  const timer = useTimer(() => {
    window.location.reload();
  }, 15 * 1000);

  useEffect(() => {
    timer.start();
  }, []);

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: "PAYMENT_PIX_SUCCESS",
      })
    );
  }, []);

  return (
    <Container>
      {/* <ReactAudioPlayer src={AudioPaymentSuccess} autoPlay /> */}
      <ViewHeader>
        <Title testID="modal_title">Obrigado, recebemos seu pagamento!</Title>
      </ViewHeader>
      <Content>
        <>
          <ImageSuccess source={Success} resizeMode="contain" />
          <TextInformationContainer>
            <ViewInformationApp>
              <TextDownloadApp>Baixe nosso app!</TextDownloadApp>
              <ImageLogo source={Logo} resizeMode="contain" />
              <TextAndroidIos>Disponível para Android e iOS</TextAndroidIos>
            </ViewInformationApp>
          </TextInformationContainer>
          <ViewButton>
            <ChangeButton onPress={handleNavigateTo}>
              <TextChange>
                Voltar ao início ({timer.time / 1000}s...)
              </TextChange>
            </ChangeButton>
            <ConfirmButton onPress={handleReceipt}>
              <TextConfirmButton>{"Ver \n comprovante"}</TextConfirmButton>
            </ConfirmButton>
          </ViewButton>
        </>
      </Content>
    </Container>
  );
}
