import React from 'react'
import * as S from './styles'
import {  useNavigation } from "@react-navigation/native";

type HeaderNavigationProps = {
  title: string
  enableButtonLeft: boolean
  enableButtonRight: boolean
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({ title, enableButtonLeft, enableButtonRight }) => {

  const navigation = useNavigation()

  return (
    <S.HeaderContainer>
        <S.HeaderButton  onPress={() => navigation.navigate('Loja')} first>
          {enableButtonLeft && 
            <S.HeaderLink>
              Voltar
            </S.HeaderLink>
          }
        </S.HeaderButton>
      <S.HeaderTitle>
        {title}
      </S.HeaderTitle>
      <S.HeaderButton onPress={() => navigation.navigate("Support")}>
            {enableButtonRight && 
              <S.HeaderLink>
                Ajuda
              </S.HeaderLink>
           }
       </S.HeaderButton>
    </S.HeaderContainer>
  )
}

export default HeaderNavigation;