import styled from "styled-components/native";
import { theme } from "../../global/theme";

interface ViewWrapperProps {
  selected: boolean;
  isSmallDevice: number;
}

interface OverlayProps {
  mode: string;
}

export const Container = styled.View<{ isSmallDevice: number }>`
  align-items: center;
  background: #fff;
  border-radius: 24px;
  border-width: 0px;
  border-color: transparent;
  width: ${(props) =>
    props.isSmallDevice < 500 ? props.isSmallDevice - 24 : 466}px;
  height: 611px;
`;

export const Content = styled.View`
  width: 100%;
`;

export const Title = styled.Text`
  font-size: 15.15px;
  font-weight: 700;
  font-family: ${theme.fonts.bold};
  margin-top: 14px;
  margin-bottom: 6px;
  text-align: center;
`;

export const Text = styled.Text`
  font-size: 15.15px;
  font-weight: 700;
  font-family: ${theme.fonts.bold};
  text-align: center;
`;

export const Description = styled.Text`
  font-size: 13px;
  font-family: ${theme.fonts.regular};
  margin-bottom: 30px;
  text-align: center;
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #949494;
  width: 100%;
`;

export const TextButton = styled.Text`
  color: #2081ff;
  font-size: 14px;
  font-family: ${theme.fonts.bold};
`;
export const IconAlert = styled.Image`
  width: 64px;
  height: 64px;
`;
export const IconContaine = styled.View`
  margin-top: 30px;
`;

export const Overlay = styled.View<OverlayProps>`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === "success"
      ? "#20AC5494"
      : props.mode === "failed"
      ? "#FF000078"
      : "#000000cc"};
`;

export const PressableFinish = styled.Pressable`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 44px;
`;

export const ViewWrapper = styled.View<ViewWrapperProps>`
  border-width: 1.5px;
  border-color: ${(props) =>
    props.selected ? theme.colors.primary : "#dadada"};
  border-radius: 10px;
  margin: 0 24px 24px 24px;
  width: ${(props) =>
    props.isSmallDevice < 500 ? props.isSmallDevice - 48 : 413}px;
`;

export const ViewSelectCards = styled.View``;

export const ViewFlex = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20.5px 24px 20.5px 24px;
`;

export const ViewRadio = styled.View`
  width: 20px;
  height: 20px;
  border-color: black;
  border-radius: 10px;
  border-width: 1px;
  margin: 10px;
  justify-content: center;
  align-items: center;
`;

export const ViewRadioBg = styled.View`
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 10px;
`;

export const PurchaseDetails = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TotalContainerContent = styled.View`
  display: flex;
  align-items: center;
  margin-left: 75.5px;
`;

export const TotalContainerContentValue = styled.View`
  display: flex;
  align-items: center;
  margin-right: 75.5px;
  margin-left: 164px;
`;

export const TotalContainerContentText = styled.Text`
  font-family: ${theme.fonts.regular};
  font-size: 15px;
  color: ${theme.colors.tertiary};
  text-align: center;
`;

export const TotalContainerContentTextValue = styled.Text`
  font-family: ${theme.fonts.bold};
  font-size: 15px;
  color: ${theme.colors.secondary};
  text-align: center;
`;

export const HeaderLink = styled.Text`
  font-size: 15px;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.bold};
  text-decoration: underline;
  text-decoration-color: ${theme.colors.primary};
`;
export const CardContainerIcon = styled.View`
  position: absolute;
  left: 24px;
`;
export const TextContainer = styled.Text`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
