import React, { useState } from "react";
import { useNavigation, RouteProp, NavigationProp } from "@react-navigation/native";
import { Header } from "../../components/Header";
import { Ionicons } from '@expo/vector-icons';
import { useCart } from "../../hooks/use-cart";
import { StyleSheet } from "react-native"
import {
  Container,
  Content,
  BackContainer,
  BackText,
  AddToCartContainer,
  OverlayOffer,
  ProductBox,
  OfferLabel,
  ProductImage,
  NoImage,
  ImageWrapper,
  ProductName,
  DescriptionBox,
  DescriptionText,
  ContainerMessage,
  Message,
  PriceWrapper,
  RiskedPrice,
  FinalProductPrice,
  Space,
  ButtonContainerAdd,
  ButtonsContainer,
  ContinueBuying,
  ContinueBuyingBorder,
  TextAddToCart,
  AreaAddProduct
} from "./styles";
import { QuantityPicker } from "../../components/QuantityPickerStore";
import { Planogram } from "../../models/planogram";
import { NumberToCurrency } from "../../utils/numberToCurrency";

interface RouteParams {
  key: string;
  name: string;
  params: PropsProduct;
  path: any;
}

interface PropsProduct {
  product: Planogram
}

type ProductRouteProp = RouteProp<Record<string, RouteParams>, 'Product'>;

type Navigation = NavigationProp<any>;

const Product: React.FC<{ route?: ProductRouteProp }> = ({ route }) => {
  const cart = useCart();
  const offer_label = require("../../assets/offer_label.png");
  const { canGoBack, goBack } = useNavigation();
  const [productQuantity, setProductQuantity] = useState(1);
  const [product, setProduct] = useState<any>(route?.params?.params?.product)
  const navigation = useNavigation<Navigation>();

  React.useEffect(() => {
    const setStateProduct = () => {
      setProduct(route?.params?.params?.product)
      setProductQuantity(1)
    }

    if (route?.params?.params?.product) {
      setStateProduct()
    }

  }, [route])

  const onBackPress = React.useCallback(() => {
    if (canGoBack()) {
      goBack();
    }
  }, [goBack]);

  const handleAddToCart = async () => {
    if (!product) return

    if (productQuantity === 0) {
      navigation.navigate("Carrinho");
      return
    };

    cart.updateCartItem(product, productQuantity, true, false, false);
  }

  return (
    <Container>
      <Header title="Produto" />
      <BackContainer
        onPress={onBackPress}
        testID="btn_back"
      >
        <BackText>Voltar</BackText>
      </BackContainer>
      <Content showsVerticalScrollIndicator={false}>
        {product && (
          <OverlayOffer>
            {product?.price_final_in_cents <
              product?.price_list_in_cents && (
                <OfferLabel source={offer_label} testID="offer_icon" />
              )}
            {product.stock_amount <= 0 && (
              <ContainerMessage>
                <Message>Esse produto pode não estar em estoque</Message>
              </ContainerMessage>
            )}
            <ProductBox>
              <ImageWrapper>
                {product?.product.image_url ? (
                  <ProductImage
                    source={{
                      uri: product?.product.image_url,
                    }}
                    resizeMode="contain"
                    testID="image"
                  />
                ) : (
                  <NoImage testID="no_image">
                    <Ionicons
                      name="image-outline"
                      size={200}
                      color="#ededed"
                    />
                  </NoImage>
                )}
              </ImageWrapper>

              <ProductName testID="name">
                {product?.product.name}
              </ProductName>
              <PriceWrapper>
                {product.price_final_in_cents <
                  product.price_list_in_cents && (
                    <RiskedPrice testID="old_price">
                      {NumberToCurrency(
                        parseFloat(product.price_list_in_cents) / 100,
                      )}
                    </RiskedPrice>
                  )}

                <FinalProductPrice
                  isOffer={Boolean(
                    product.price_final_in_cents <
                    product.price_list_in_cents,
                  )}
                  testID="price"
                >
                  {NumberToCurrency(
                    parseFloat(product.price_final_in_cents) / 100,
                  )}
                </FinalProductPrice>
              </PriceWrapper>
            </ProductBox>
          </OverlayOffer>
        )}

        {product && product?.product?.description && (
          <DescriptionBox testID="description">
            <DescriptionText>{product.product.description}</DescriptionText>
          </DescriptionBox>
        )}
        <Space />
      </Content>

      <AddToCartContainer
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 11,
          },
          shadowOpacity: 0.55,
          shadowRadius: 14.78,
          elevation: 22,
        }}
      >
        <TextAddToCart>
            Quantidade a adicionar
        </TextAddToCart>
       <AreaAddProduct>
        <ButtonContainerAdd>
            <QuantityPicker
              value={productQuantity}
              maxValue={productQuantity + 2}
              onChange={setProductQuantity}
              testID={product?.id.toString()}
              type="full"
            />
          </ButtonContainerAdd>
       </AreaAddProduct>
      </AddToCartContainer>
      <ButtonsContainer
        style={{
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 11,
          },
          shadowOpacity: 0.55,
          shadowRadius: 14.78,
          elevation: 22,
        }}>
        <ContinueBuyingBorder type="primary_ghost" onPress={onBackPress}>
          Voltar
        </ContinueBuyingBorder>

        <ContinueBuying type="secondary" onPress={handleAddToCart}>
          {productQuantity === 0 ? "Ir para o carrinho" : "Adicionar ao carrinho"}
        </ContinueBuying>
      </ButtonsContainer>
    </Container>
  );
};

export default Product;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  leftContainer: {
    flex: 0.65,
    marginRight: 10,
  },
  rightContainer: {
    flex: 1,
    marginLeft: 10,
  },
});