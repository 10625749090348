import React, { Dispatch, SetStateAction, useEffect } from "react";

import {
  Container,
  Content,
  Title,
  ConfirmButton,
  TextChange,
  TextConfirmButton,
  TextInformation,
  ChangeButton,
  TextInformationContainer,
  ViewButton,
  ChangeClose,
  ViewHeader,
  ImageClose,
  ImageFailed,
} from "./styles";

import Failled from "../../../assets/failled.png";
import Close from "../../../assets/closebutton.png";
import { useTimer } from "../../../hooks/useTimer";
import { useShellData } from "../../../providers/shell-provider";

interface PropsFailed {
  setMode: React.Dispatch<React.SetStateAction<string>>;
  tryAgain?: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setShowSupport?: Dispatch<SetStateAction<boolean>>;
  setFlow?: React.Dispatch<React.SetStateAction<string>>;
  errorPaymentModal: {
    title: string;
    description: string;
  };
}

export default function PaymentFailed({
  setMode,
  tryAgain,
  setOpen,
  setShowSupport,
  setFlow,
  errorPaymentModal,
}: PropsFailed) {
  const shellData = useShellData();

  setMode("failed");

  const modalSupport = () => {
    setOpen(false);
    if (setShowSupport) {
      setShowSupport(true);
    }
  };

  const retry = () => {
    if (setFlow && shellData?.pos.paired && shellData?.pos.nearby) {
      setMode("");
      setFlow("selectMethods");
      return;
    }

    setOpen(false);
  };

  const timer = useTimer(() => {
    window.location.reload();
  }, 15 * 1000);

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <Container>
      <ViewHeader>
        <Title testID="modal_title">
          {errorPaymentModal?.title || "Falha na operação"}
        </Title>
        <ChangeClose onPress={() => setOpen(false)}>
          <ImageClose source={Close} />
        </ChangeClose>
      </ViewHeader>
      <Content>
        <ImageFailed source={Failled} resizeMode="contain" />
        <TextInformationContainer>
          <TextInformation>
            {errorPaymentModal?.description ||
              "Desculpe-nos, algo deu errado ao processar seu pagamento."}
          </TextInformation>
        </TextInformationContainer>
        <ViewButton>
          <ChangeButton onPress={modalSupport}>
            <TextChange>{"Falar com \n o suporte"}</TextChange>
          </ChangeButton>
          <ConfirmButton onPress={retry}>
            <TextConfirmButton>{"Tentar \n novamente"}</TextConfirmButton>
          </ConfirmButton>
        </ViewButton>
      </Content>
    </Container>
  );
}
