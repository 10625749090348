import React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { NavigationProp, useNavigation } from "@react-navigation/native";
import styled from 'styled-components/native';
import { theme } from '../../global/theme';

export const ContainerBtnSearch = styled.TouchableOpacity`
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 24px;
  height: 64px;
  width: 100%;
  background-color: ${theme.colors.primary};
  justify-content: center;
  border-radius: 8px;
  flex-direction: row;
`;

export const IconWrapper = styled.View`
  position: absolute;
  left: 24px; 
`;

export const ContainerButton = styled.View`
  margin-left: 24px;
  margin-right: 24px;
`;

export const SearchText = styled.Text`
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

type Navigation = NavigationProp<any>;

const SearchButton: React.FC = () => {
  const navigation = useNavigation<Navigation>();

  const handleNavigateTo = () => {
    navigation.navigate("PesquisarProdutos");
  };

  return (
    <ContainerButton>
      <ContainerBtnSearch onPress={handleNavigateTo}>
        <IconWrapper>
          <FontAwesome name="search" size={22} color="white" />
        </IconWrapper>
        <SearchText>Buscar produto pelo nome</SearchText>
      </ContainerBtnSearch>
    </ContainerButton>
  );
}

export default SearchButton;