import React, { ReactElement, ReactFragment } from "react";
import {
  Container,
  Header,
  SeeMoreButton,
  SeeMoreText,
  ItemsHorizontalScroll,
  ScrollInner,
  Title,
} from "./styles";

interface BaseClassProps {
  title: string;
  seeMoreAction?: () => void;
  children: ReactElement;
}

export const BaseClass: React.FC<BaseClassProps> = ({
  title,
  seeMoreAction,
  children,
}) => {
  return (
    <Container>
      <Header>
        <Title testID="base_class_title">{title}</Title>
        {seeMoreAction && (
          <SeeMoreButton onPress={seeMoreAction} testID="btn_see_more">
            <SeeMoreText>Ver mais</SeeMoreText>
          </SeeMoreButton>
        )}
      </Header>
      <ItemsHorizontalScroll horizontal showsHorizontalScrollIndicator={false}>
        <ScrollInner>{children}</ScrollInner>
      </ItemsHorizontalScroll>
    </Container>
  );
};
