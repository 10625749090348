import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal as ReactNativeModal } from "react-native";

import { Overlay } from "./styles";
import PaymentFailed from "./Failed";
import PaymentSuccess from "./Success";
import MethodsPayments from "./Methods";
import { Receipt } from "../../pages/payment";

export interface ModalProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  description?: string;
  action?: VoidFunction;
  setSelectedPayment?: (value: string) => void;
  setShowSupport?: Dispatch<SetStateAction<boolean>>;
  selectedPayment?: string;
  onFinished?: (value: string) => void;
  loading?: boolean;
  flow?: string;
  setFlow?: React.Dispatch<React.SetStateAction<string>>;
  tryAgain?: () => void;
  receipt?: Receipt;
  errorPaymentModal: {
    title: string;
    description: string;
  }
}

const ModalPayments = ({
  isOpen,
  setOpen,
  selectedPayment,
  setSelectedPayment,
  setShowSupport,
  onFinished,
  loading,
  flow,
  tryAgain,
  receipt,
  setFlow,
  errorPaymentModal,
}: ModalProps) => {
  const [mode, setMode] = useState("");
  return (
    <ReactNativeModal
      animationType="slide"
      visible={isOpen}
      transparent={true}
      onRequestClose={() => {
        setOpen(false);
      }}
      style={{ borderWidth: 0, borderColor: "transparent" }}
    >
      <Overlay mode={mode}>
        {flow === "selectMethods" && (
          <MethodsPayments
            setOpen={setOpen}
            loading={loading}
            onFinished={onFinished}
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
          />
        )}
        {flow === "success" && (
          <PaymentSuccess
            setMode={setMode}
            setOpen={setOpen}
            receipt={receipt}
          />
        )}
        {flow === "failed" && (
          <PaymentFailed
            setMode={setMode}
            tryAgain={tryAgain}
            setOpen={setOpen}
            setShowSupport={setShowSupport}
            setFlow={setFlow}
            errorPaymentModal={errorPaymentModal}
          />
        )}
      </Overlay>
    </ReactNativeModal>
  );
};

export default ModalPayments;
