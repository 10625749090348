import styled from "styled-components/native";
import { theme } from "../../../../global/theme";

export const CartItemContainer = styled.View<{ isLast: boolean }>`
	flex-direction: row;
	margin: 0 20px;
	padding-vertical: 24px;
	border-bottom-width: ${props => props.isLast ? '0px' : '0.5px'};
	border-bottom-color: ${theme.colors.border};
`;

export const CartItemRow = styled.View`
	justify-content: center;
	align-items: flex-start;
`;

export const CartImageContainer = styled.Pressable`
	width: 50px;
	border-radius: 8px;
	justify-content: center;
	margin-right: 16px;
	elevation: 1;
	align-items: flex-start;
`;

export const TextUn = styled.Text`
	font-size: 14px;
	font-family: ${(props) => `${theme.fonts.regular}`};
`;

export const CartItemImage = styled.Image``;

export const CartDetailsContainer = styled.View`
	justify-content: space-between;
	margin-right: 16px;
	flex: 1;
`;

interface CarCartItemNameProps {
	bold?: boolean;
}
export const CartItemName = styled.Text<CarCartItemNameProps>`
	font-size: 14px;
	font-family: ${(props) =>
		props.bold ? `${theme.fonts.semiBold}` : `${theme.fonts.regular}`};
		margin-bottom: 8px;
`;

export const CartPriceContainer = styled.View`
	justify-content: end;
`;

export const DiscountCartItemPrice = styled.Text`
	font-size: 18px;
	text-decoration: line-through;
	text-align: center;
	font-family: ${theme.fonts.regular};
	color: ${theme.colors.gray};
`;

interface CartItemPriceProps {
	bold?: boolean;
}
export const CartItemPrice = styled.Text<CartItemPriceProps>`
	font-size: 14px;
	text-align: center;
	font-family: ${(props) =>
		props.bold ? `${theme.fonts.semiBold}` : `${theme.fonts.regular}`};
`;

export const CartPrice = styled.Text`
		font-size: 18px;
		font-family: ${theme.fonts.semiBold};
		text-align: center;
		color: #000000;
`

export const PriceContainerFiller = styled.View`
	flex: 1;
`;
