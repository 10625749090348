import styled, { css } from "styled-components/native";
import { theme } from "../../global/theme";

interface Props {
	selected: boolean;
}

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	flex: 1;
`;

export const CategoriesContainer = styled.ScrollView`
	margin-top: 20px;
`;

export const TitleCategories = styled.Text`
	padding-bottom: 14px;
	padding-left: 25px;
	font-size: 18px;
	font-family: ${theme.fonts.bold};
`;

export const CategoriesContainerContent = styled.ScrollView`
	padding-left: 25px;
	flex-direction: row;
	margin-bottom: 25px;
`;

export const CategoriesContent = styled.TouchableOpacity<Props>`
	padding: 10px 20px;
	border-radius: 30px;
	justify-content: center;
	margin-right: 14px;
	background-color: ${theme.colors.lightGray};
	${(props) => {
		if (props.selected) {
			return css`
				background-color: ${theme.colors.primary};
			`;
		}
	}}
`;

export const TextCategories = styled.Text<Props>`
	font-size: 12px;
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.primary};
	text-align: center;
	${(props) => {
		if (props.selected) {
			return css`
				color: ${theme.colors.white};
			`;
		}
	}}
`;

export const Products = styled.View`
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 32px;
`;

export const ButtonContainer = styled.View`
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 50px;
`;

export const TextLoadingCategory = styled.Text`
	font-size: 15px;
	font-family: ${theme.fonts.bold};
`;
