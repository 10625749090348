import styled from "styled-components/native";
import { theme } from "../../../global/theme";

interface ViewWrapperProps {
  selected: boolean;
  isSmallDevice: number;
}

interface OverlayProps {
  mode: string;
}

interface MethodEnabled {
  enabled: boolean;
}

export const Container = styled.View`
  align-items: center;
  background: #fff;
  border-radius: 24px;
  border-width: 0px;
  border-color: transparent;
  width: 100%;
  max-width: 562px;
  height: 100%;
  max-height: 820px;
`;

export const Content = styled.View`
  width: 100%;
`;

export const Title = styled.Text`
  font-size: 25px;
  font-family: ${theme.fonts.bold};
  margin-top: 23px;
  margin-bottom: 29px;
  text-align: center;
`;

export const Overlay = styled.View<OverlayProps>`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.mode === "sucess"
      ? "#20AC5494"
      : props.mode === "failed"
      ? "#FF000078"
      : "#000000cc"};
`;

export const PressableFinish = styled.Pressable`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-width: 1.5px;
  border-color: #dadada;
  border-radius: 10px;
`;

export const ViewWrapper = styled.View<ViewWrapperProps>`
  border-width: 1.5px;
  border-color: ${(props) =>
    props.selected ? theme.colors.primary : "#dadada"};
  border-radius: 10px;
  margin: 0 24px 20px 24px;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

export const ViewContainer = styled.View`
  margin-top: 27px;
  padding: 0 35.5px 0px 35.5px;
`;

export const TouchableOpacityBtn = styled.TouchableOpacity<MethodEnabled>`
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.enabled ? 1 : 0.5)};
  border: none;
`;

export const ImageCard = styled.Image`
  width: 29px;
  height: 25px;
`;

export const ImagePix = styled.Image`
  width: 29px;
  height: 25px;
`;

export const ViewSelectCards = styled.View``;

export const ViewFlex = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20.5px 24px 20.5px 24px;
`;

export const PurchaseDetails = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 27px;
  border-color: #dadada;
  border-bottom-width: 0.42;
`;

export const TotalContainerContent = styled.View`
  display: flex;
  align-items: center;
  margin-left: 75.5px;
`;

export const TotalContainerContentValue = styled.View`
  display: flex;
  align-items: center;
  margin-right: 75.5px;
  margin-left: 164px;
`;

export const TotalContainerContentTextValue = styled.Text`
  font-family: ${theme.fonts.bold};
  font-size: 23px;
  color: ${theme.colors.secondary};
  text-align: center;
`;

export const HeaderLink = styled.Text`
  font-size: 23px;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.medium};
`;

export const CardContainerIcon = styled.View`
  position: absolute;
  left: 24px;
`;

export const TextContainer = styled.Text`
  font-size: 23px;
  font-weight: bold;
  text-align: center;
`;

export const ContainerPressable = styled.View`
  width: 100%;
  margin-top: 40px;
  position: absolute;
  bottom: 23;
  padding: 0px 24px 0px 24px;
`;
