import {
	CommonActions,
	useIsFocused,
	useNavigation,
	useRoute,
} from "@react-navigation/native";
import React, { createRef, useEffect, useRef, useState } from "react";
import {
	RefreshControl,
	View,
} from "react-native";
import QRCode from "react-native-qrcode-svg";
import ViewShot from "react-native-view-shot";
import { useStore } from "../../hooks/use-store";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { PurchaseItem } from "./components/PurchaseItem";
import {
	ConfirmButton,
	Container,
	ContainerQRCode,
	ContentButtonProducts,
	DisabledView,
	ItemsScroll,
	LineContainer,
	LineText,
	PaymentDetailsContainer,
	PaymentDetailsTitle,
	PaymentLineContainer,
	PaymentTitle,
	StoreContainer,
	StoreDetailsContainer,
	StoreImage,
	StoreLocation,
	StoreName,
	TextConfirmButton,
	TextDetails,
	TotalContainer,
	TotalValue,
	ProofPaymentDateArea,
	ProofPaymentDate,
	ProofPaymentStatus,
	DetailsOrder,
	ShareProof,
	IconProof,
	LineArea
} from "./styles";
import LoadingPage from "../../components/LoadingPage";
import {
	InvoiceStatus,
	PurchaseDetails as PurchaseDetailsModel,
} from "../../models/purchases";
import { Store } from "../../models/store";
import api from "../../services/api";
import { TIME_TO_UPDATE_PURCHASE_STATUS } from "../../utils/contants";
import { formatDate } from "../../utils/formatDate";
import { getInvoiceStatus } from "../../utils/iugu";
import ModalSendReceipt from "../../components/ModalSendReceipt";
import HeaderNavigation from "../../components/HeaderNavigation";

const pixImage = require("../../assets/pix.svg");
const cardImage = require("../../assets/card.svg");

const PurchaseDetails2: React.FC = () => {
	const focused = useIsFocused();
	const route = useRoute();
	const navigation = useNavigation();
	const [purchase, setPurchase] = useState<PurchaseDetailsModel>();
	const paramStore = useStore();
	const [store, setStore] = useState<Store>(() => null as any);
	const [salesId] = useState<Number>(() => (route.params as any).purchaseId);
	const [paymentMethod] = useState<string | null>(() => (route.params as any).paymentMethod);
	const [isProofPurchase] = useState(() => true);
	const [isPixPurchase] = useState(() => true);
	const [loading, setLoading] = useState(false);
	const [modalPaid, setModalPaid] = useState(false);
	const viewShot = useRef<any>();
	const [modalErrorPay, setModalErrorPay] = useState(false);
	const [hidden, setHidden] = useState(false);
	const ref = createRef<HTMLDivElement>();

	const getImage = async () => {
		setHidden(true)
	};

	useEffect(() => {
		navigation.setOptions({ tabBarStyle: { display: 'none' } });
		const controller = new AbortController();
		getStore();
		loadPurchases(controller.signal);
		return () => {
			controller.abort();
		};
	}, [salesId, focused]);

	const getStore = () => {
		api.get(`/store/${paramStore.storeId}`)
			.then((response) => {
				setStore(response.data);
			})
			.catch((error) => {
			});
	};

	const loadPurchases = async (signal?: AbortSignal) => {
		if (!salesId) return;

		setLoading(true);

		api.get<PurchaseDetailsModel[]>(
			`/sales/history/show?salesId=${salesId}`,
			{
				signal: signal,
			}
		)
			.then((response) => {
				const purchaseRes = response.data[0];
				setPurchase(purchaseRes);
				setLoading(false);
			})
			.catch((err) => {
			});
	};

	const checkPurchasePayment = async () => {
		if (!salesId) {
			setTimeout(checkPurchasePayment, TIME_TO_UPDATE_PURCHASE_STATUS);
			return;
		}
		try {
			const response = await api.get<PurchaseDetailsModel[]>(
				`/sales/history/show?salesId=${salesId}`
			);

			const purchaseRes = response.data[0];
			setPurchase(purchaseRes);

			if (purchaseRes.status === "canceled") {}

			if (purchaseRes.status === "paid") {
				setLoading(false);
				setModalPaid(true);
			} else {
				setTimeout(
					checkPurchasePayment,
					TIME_TO_UPDATE_PURCHASE_STATUS
				);
			}
		} catch (error: any) {
			setLoading(false);
			setModalErrorPay(true);
		}
	};

	const getFormattedDate = (rawDateHour: string) => {
		const date = formatDate(new Date(rawDateHour));
		const hour = rawDateHour.match(/\d\d:\d\d:\d\d/);
		return date + " - " + hour?.[0];
	};

	const handleNavigateTo = (
		screenName: string,
		params: Object | undefined = undefined
	) => {
		navigation.dispatch(
			CommonActions.navigate(
				params
					? {
						name: screenName,
						params,
					}
					: {
						name: screenName,
					}
			)
		);
	};

	const getDiscountInCents = () => {
		if (!purchase) return 0;

		const listPriceTotal = purchase?.saleItems.reduce((total, item) => {
			return total + item.listPrice * item.quantity;
		}, 0);

		return purchase.total < listPriceTotal
			? listPriceTotal - purchase.total
			: 0;
	};

	const getColorStatus = (status: InvoiceStatus) => {
		if (status === "canceled") return "red";
		if (status === "paid") return "green";
		if (status === "paid_under_validation") return "green";
		return "yellow";
	};

	if (loading) {
		return (
			<>
				{isProofPurchase && !isPixPurchase && (
					<View
						style={{
							position: "absolute",
							top: 100,
							left: 0,
							right: 0,
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<LineText
							testID="status"
						>
							Por favor, aguarde confirmação
						</LineText>
					</View>
				)}
				<LoadingPage />
			</>
		);
	}

	return (
		<>
			<Container>
				<ItemsScroll
					refreshControl={
						<RefreshControl
							refreshing={loading}
							onRefresh={
								isProofPurchase ? () => { } : loadPurchases
							}
						/>
					}
				>
					<div ref={ref}>
						<ViewShot
							options={{ format: "jpg", quality: 0.9 }}
							style={{ flex: 1, backgroundColor: "#FFF" }}
							ref={viewShot}
						>
							{purchase && (
								<>
									<HeaderNavigation enableButtonLeft={true} enableButtonRight={false} title="Comprovante de compra" />
									<ProofPaymentDateArea>
										<ProofPaymentDate>
											{getFormattedDate(
												purchase.createdAt
											)}
										</ProofPaymentDate>
										<ProofPaymentStatus
											color={getColorStatus(
												purchase.status
											)}
										>
											{getInvoiceStatus(purchase.status)}
										</ProofPaymentStatus>
									</ProofPaymentDateArea>

									<StoreContainer>
										<StoreImage
											source={{ uri: store?.logo }}
											testID="image"
										/>
										<StoreDetailsContainer>
											<StoreName
												testID="name"
												numberOfLines={1}
												ellipsizeMode="tail"
											>
												{store?.name}
											</StoreName>
											<StoreLocation testID="address">
												{store?.address.street}
											</StoreLocation>
										</StoreDetailsContainer>
									</StoreContainer>


									<DisabledView
										isCanceled={
											purchase.status === "canceled"
										}
									>
										<TotalContainer>
											<TotalValue testID="total_label">
												Total (
												{purchase.saleItems.length} un)
											</TotalValue>
											<TotalValue testID="total_price">
												{NumberToCurrency(
													purchase.total / 100
												)}
											</TotalValue>
										</TotalContainer>
									</DisabledView>

									<DisabledView
										isCanceled={
											purchase.status === "canceled"
										}
									>
										{purchase.saleItems.map(
											(item, index) => (
												<PurchaseItem
													key={index}
													item={item}
													isLast={purchase.saleItems.length - 1 === index}
												/>
											)
										)}
									</DisabledView>
									<LineArea>
										<LineContainer first>
											<LineText>
												Subtotal
											</LineText>
											<LineText>
												{NumberToCurrency(
													purchase.total / 100
												)}
											</LineText>
										</LineContainer>
										<LineContainer>
											<LineText>
												Desconto
											</LineText>
											<LineText>
												{NumberToCurrency(
													getDiscountInCents() / 100
												)}
											</LineText>
										</LineContainer>
										<LineContainer isLast>
											<LineText >
												Total
											</LineText>
											<LineText>
												{NumberToCurrency(
													purchase.total / 100
												)}
											</LineText>
										</LineContainer>
									</LineArea>

									<DetailsOrder>
										<TotalValue testID="total_label">
											Total (
											{purchase.saleItems.length} un)
										</TotalValue>
										<TotalValue testID="total_price">
											{NumberToCurrency(
												purchase.total / 100
											)}
										</TotalValue>
									</DetailsOrder>
									<PaymentTitle>Pagamento</PaymentTitle>
									<PaymentLineContainer testID="payment_cc">
										{
											!paymentMethod ? <img
												alt="pix"
												src={pixImage}
											/> : <img
												alt="card"
												src={cardImage}
											/>
										}
										<PaymentDetailsContainer>
											<PaymentDetailsTitle>
												{!paymentMethod ? "PIX" : paymentMethod === 'plugpag_credit' ? "CRÉDITO" : "DÉBITO"}
											</PaymentDetailsTitle>
										</PaymentDetailsContainer>
									</PaymentLineContainer>
									<ContainerQRCode>
										<QRCode
											size={200}
											value={purchase.id.toString()}
										/>
										<TextDetails>
											{`ID da Transação\nN° ${purchase.id}`}
										</TextDetails>
									</ContainerQRCode>
								</>
							)}
						</ViewShot>
					</div>
				</ItemsScroll>
				<ModalSendReceipt isOpen={hidden} setOpen={setHidden} saleId={route?.params?.saleId as number} />
				<ContentButtonProducts>
					<ShareProof onPress={() => getImage()}>
						<IconProof source={require('../../../assets/share.svg')} />
					</ShareProof>
					<ConfirmButton onPress={() => handleNavigateTo("Loja", {})}>
						<TextConfirmButton>
							Voltar ao início
						</TextConfirmButton>
					</ConfirmButton>
				</ContentButtonProducts>
			</Container>
		</>
	);
};

export default PurchaseDetails2;
