import styled from 'styled-components/native';
import { theme } from '../../global/theme';

export const Container = styled.View`
  align-items: center;
  background: #fff;
  border-radius: 10px;
  width: 70%;
`;

export const Content = styled.View`
  padding: 0 20px;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: 18px;
   font-family: ${theme.fonts.bold};
  margin-top: 14px;
  margin-bottom: 6px;
  text-align: center;
`;

export const Description = styled.Text`
 font-size: 13px;
 font-family: ${theme.fonts.regular};
  margin-bottom: 30px;
  text-align: center;
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: space-between;
  border-top-width: 1px;
  border-top-color: #949494;
  width: 100%;
`;

export const TextButton = styled.Text`
  color: #2081ff;
   font-size: 14px;
   font-family: ${theme.fonts.bold};
`;
export const IconAlert = styled.Image`
	width: 64px;
	height: 64px;
`;
export const IconContaine = styled.View`
  margin-top: 30px;
`;

export const Overlay = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
`;